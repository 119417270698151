<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        placeholder=" "
                        :initial="filters['termin.von']"
                        :show-icon="true"
                        :tabindex="1"
                        field="termin.von"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.from") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        placeholder=" "
                        :initial="filters['termin.bis']"
                        :show-icon="true"
                        :tabindex="2"
                        field="termin.bis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.till") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="dozentenbuchung-veranstaltung"
                      :label="$t('global.event')"
                      :veranstaltung="filters.veranstaltung"
                      :allowOpen="false"
                      editable
                      :tabindex="5"
                      @confirmed="setVeranstaltung"
                    />
                  </div>
                </div>

                <div class="row" v-if="!isDozent">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filters.dozent"
                      :label="$t('global.lecturer')"
                      :allowOpen="false"
                      :editable="true"
                      :tabindex="3"
                      @confirmed="setFilterDozent"
                    />
                  </div>
                </div>

                <div class="row" v-if="!isDozent">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.planungsstatus"
                        :options="planungsstati"
                        :reduce="(p) => p.id"
                        label="bezeichnung"
                        :tabindex="6"
                        :placeholder="$t('global.statusplanning')"
                        multiple
                      >
                      </v-select>
                      <label>{{ $t("global.statusplanning") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="!isDozent">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.durchfuehrungsstatus"
                        :options="durchfuehrungsstati"
                        :reduce="(p) => p.id"
                        label="bezeichnung"
                        :tabindex="7"
                        :placeholder="$t('global.statusexecution')"
                        multiple
                      >
                      </v-select>
                      <label>{{ $t("global.statusexecution") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="!isDozent">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.anstellungsart"
                        :options="anstellungsarten"
                        :reduce="(aa) => aa.id"
                        label="bezeichnung"
                        :tabindex="4"
                        :placeholder="$t('global.typesofemployment')"
                      >
                      </v-select>
                      <label>{{ $t("global.typesofemployment") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        :tabindex="8"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <!-- ausgeblendet, bis  -->
                    <!-- <button
                      v-if="berechtigungen.m_dozierende.delete"
                      class="btn btn-primary mr-2"
                      @click="ausgabeEinsatzliste"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      {{ $t("global.deploymentlist") }}
                    </button> -->
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_dozierende.update"
                      @click="markiereDurchgefuehrt"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-circle-check"
                        class="mr-2"
                      />
                      {{ $t("global.settodone") }}
                    </button>
                    <button
                      v-if="filters.dozent != null"
                      class="btn btn-primary mr-2"
                      @click="carbone()"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      {{ $t("global.workitimeaccounting") }}
                    </button>
                    <button
                      v-if="berechtigungen.m_dozierende.delete"
                      class="btn btn-primary mr-2"
                      @click="exportToExcel(listData, 'dozenteneinsätze')"
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.export") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_dozierende.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortTermine"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(abgeschlossen)="row">
                      <font-awesome-icon
                        v-if="row.item.abgeschlossen"
                        style="color: green"
                        icon="fa-solid fa-check"
                      />
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
        <pdf-layer
          id="pdf-layer-dozenteneinsaetze"
          ref="pdfLayerDozenteneinsaetze"
          :src="{
            url: pdfPath,
            withCredentials: true,
          }"
          :files="pdfFiles"
        ></pdf-layer>
      </div>
    </div>

    <loeschen-modal
      id="dozenteneinsatz-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />

    <messagebox-count
      :headerText="$t('global.lecturerdeploymentsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import CarboneApi from "@/CarboneApi";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import excelExport from "@/mixins/ExcelExport";

import server from "@/server";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_DOZENT } from "@/store/dozent/actions.type";
import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";

export default {
  name: "Dozenteneinsatzliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    DatePicker,
    SingleSelectPerson,
    LoeschenModal,
    SingleSelectVeranstaltung,
    FilterSearchResetButton,
  },
  mixins: [page, dashboard, excelExport, filter],
  store,
  data() {
    return {
      isDozent: false,
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,
      test: [],

      dozenteneinsaetze: [],
      pdfFileName: "",
      pdfPath: "",
      pdfFiles: [],
      einsatzObj: {},
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "abgeschlossen",
          tdClass: "text-center",
          sortable: true,
          searchable: false,
          label: "",
        },
        {
          key: "termin.bis",
          sortable: true,
          label: this.$t("global.date"),
        },
        {
          key: "termin.von_uhrzeit",
          searchable: false,
          sortable: true,
          label: this.$t("global.from"),
        },
        {
          key: "termin.bis_uhrzeit",
          searchable: false,
          sortable: true,
          label: this.$t("global.till"),
        },
        {
          key: "dozentenbuchung.veranstaltung.kuerzel",
          searchable: true,
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "dozentenbuchung.veranstaltung.bezeichnung",
          searchable: true,
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "dozentenbuchung.person.personName",
          searchable: true,
          sortable: true,
          label: this.$t("global.lecturer"),
        },
        {
          key: "termin.lektionen",
          searchable: false,
          sortable: true,
          label: this.$t("global.lessons"),
          tdClass: "text-center",
        },
        {
          key: "planungsstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.statusplanning"),
        },
        {
          key: "durchfuehrungsstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.statusexecution"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.filterVon = this.filters["termin.von"];
      params.filterBis = this.filters["termin.bis"];

      params.dozent = this.filters.dozent?.id;
      params.filterBezeichnung = this.filters.bezeichnung;
      params.veranstaltung = this.filters.veranstaltung?.id;
      params.anstellung = this.filters.anstellungsart;
      params.filterKurzbezeichnungVeranstaltung =
        this.filters["dozentenbuchung.veranstaltung.kuerzel"];
      params.filterBezeichnungVeranstaltung =
        this.filters["dozentenbuchung.veranstaltung.bezeichnung"];
      params.filterDozentName =
        this.filters["dozentenbuchung.person.personName"];

      params.durchfuehrungsstatus = this.filters.durchfuehrungsstatus
        ? this.filters.durchfuehrungsstatus.join(",")
        : null;

      params.planungsstatus = this.filters.planungsstatus
        ? this.filters.planungsstatus.join(",")
        : null;

      // TODO

      //params.filterVonUhrzeit = this.filters['termin.von_uhrzeit']
      //params.filterBisUhrzeit = this.filters['termin.bis_uhrzeit']
      //params.filterVKuerzel = this.filters['dozentenbuchung.veranstaltung.kuerzel']
      //params.filterVBez = this.filters['dozentenbuchung.veranstaltung.bezeichnung']
      //params.filterPerson = this.filters['dozentenbuchung.person.personName']
      //params.filterLektionen = this.filters['termin.lektionen']
      //params.filterPSBez = this.filters['planungsstatus.bezeichnung']
      //params.filterDSBez = this.filters['durchfuehrungsstatus.bezeichnung']

      if (this.filters.count != "") params.filterCount = this.filters.count;

      return params;
    },

    planungsstati: {
      get() {
        return this.$store.state.dozent.planungsstati;
      },
    },
    durchfuehrungsstati: {
      get() {
        return this.$store.state.dozent.durchfuehrungsstati;
      },
    },
    anstellungsarten: {
      get() {
        return this.$store.state.geschaeftspartner.anstellungsarten;
      },
    },
    navbarTitel() {
      return this.$t("global.lecturerdeployments");
    },
  },
  created() {
    if (this.planungsstati.length == 0)
      this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);

    if (this.anstellungsarten.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    /*
    const today = new Date();
    let bisDatum =
      today.getDate() +
      "." +
      (today.getMonth() + 1) +
      "." +
      today.getFullYear();
      */

    this.initFilter("dozenteneinsatz-liste", "dozenteneinsatz/", true, {});

    //this.filters["termin.bis"] = bisDatum;
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR
      )
    )
      this.isDozent = true;
  },

  methods: {
    setFilterDozent(person) {
      this.$set(this.filters, "dozent", person);
    },

    setVeranstaltung(veranstaltung) {
      this.$set(this.filters, "veranstaltung", veranstaltung);
    },

    details(dozenteneinsaetze) {
      this.$router.push({
        name: "dozenteneinsatz",
        params: { id: dozenteneinsaetze.id, anzeige: 0 },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "dozenteneinsatz-loeschen-modal");
    },

    loeschen() {
      this.delete("dozenteneinsatz/", this.selectedIds);
    },

    markiereDurchgefuehrt() {
      if (this.selectedIds.length > 0) {
        let curDate = new Date();

        let json = {
          entityname: "Dozenteneinsatz",
          fields: ["Durchfuehrungsstatus", "Durchgefuehrt"],
          values: [
            this.$CONST("DOZENTENDURCHFUEHRUNGSTATI").EXECUTED,
            curDate.getDate() +
              "." +
              (curDate.getMonth() + 1) +
              "." +
              curDate.getFullYear(),
          ],
          ids: this.selectedIds,
        };

        // Falls Status Durchgeführt in liste
        if (
          this.selectedObjects.filter(
            (e) => e.durchfuehrungsstatus?.id === "175748EC2CD"
          ).length > 0
        ) {
          this.$notify({
            type: "warn",
            title: this.$t("global.actionfailed"),
            text: this.$t(
              "global.selectioncontainsperformedlecturerdeployments"
            ),
          });
        } else {
          Api.put("tools/massupdate/", json).then(() => {
            this.resetLoading(this);
            Api.post("finanzen/honorar/", { ids: this.selectedIds });
          });
        }
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nolecturerdeploymentselected"),
        });
      }
    },

    ausgabeEinsatzliste() {
      this.pdfFiles = [];
      let sortierteEinsaetze = [];
      this.einsatzObj = [];

      if (this.selectedObjects?.length > 0) {
        this.selectedObjects.forEach((einsatz) => {
          if (!this.einsatzObj[einsatz.dozentenbuchung?.person?.id]) {
            this.einsatzObj[einsatz.dozentenbuchung?.person?.id] = {
              personName: einsatz.dozentenbuchung?.person?.personName,
              einsaetzeIds: einsatz.id,
              anzahlEinsaetze: 1,
            };
          } else {
            this.einsatzObj[einsatz.dozentenbuchung?.person?.id].einsaetzeIds +=
              "," + einsatz.id;
            this.einsatzObj[einsatz.dozentenbuchung?.person?.id]
              .anzahlEinsaetze++;
          }
        });

        //Momentan noch keine Begrenzung fuer Anzahl Einsaetze. Einbauen anhand von anzahlEinsaetze und size anpassen, falls gefordert

        sortierteEinsaetze = this.alphabetischSortieren(
          Object.values(this.einsatzObj)
        );

        sortierteEinsaetze.forEach((entry, index) => {
          this.generatePDF(index, entry);
        });
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nolecturerdeploymentselected"),
        });
      }
    },

    generatePDF(index, einsatz) {
      let json = {
        idlist: einsatz.einsaetzeIds,
      };

      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = einsatz?.personName;
        filename += new Intl.DateTimeFormat("ch").format(new Date());
        filename = filename.split(" ").join("");
        let size = einsatz?.personName;
        if (size) size = size.trim();

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=Dozenteneinsatz" +
          filename +
          ".pdf&report=/api/pdfgenerator/dozenteneinsatzliste.htm&cache=" +
          cacheId;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            url: server.url + response.data.filepath,
            withCredentials: true,
            count: Object.entries(this.einsatzObj).length,
            size: size,
          };

          this.pdfFiles.push(retValue);
        });
      });
    },

    alphabetischSortieren(einsaetze) {
      return einsaetze.sort((a, b) => {
        if (!a.personName) {
          a.personName = "Name not found";
        }
        if (!b.personName) {
          b.personName = "Name not found";
        }
        return a.personName.localeCompare(b.personName);
      });
    },

    /* Ausgabe mit alter PDFEngine (vor Februar 2022)
    ausgabeEinsatzliste() {
      if (this.selectedIds.length > 0) {
        this.selectedIds = Array.from(new Set(this.selectedIds));
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=DEL${filename}.pdf&report=/pdfgenerator/dozenteneinsatz-liste.htm&ids=${this.selectedIds}`;
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: "Aktion fehlgeschlagen",
          text: "Keine Einsätze ausgewählt",
        });
      }
    },
*/
    sortTermine(datumA, datumB, key) {
      if (key != "termin.bis") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolveTermin(key, datumA);
          b = this.resolveTermin(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    resolveTermin(path, obj = self, separator = ".") {
      var properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    },
    carbone() {
      CarboneApi.get(
        "carbone-arbeitszeitabrechnung/?dozent=" +
          this.filters.dozent?.id +
          "&von=" +
          this.filters["termin.von"] +
          "&bis=" +
          this.filters["termin.bis"]
      )
        .then((response) => {
          this.pdfFiles = response.data.map((item) => ({
            url: server.url + item.url,
            name: item.name,
            count: item.count,
            size: item.name,
          }));

          this.pdfFiles.length > 0
            ? this.$refs.pdfLayerDozenteneinsaetze.show()
            : this.$notify({
                type: "warn",
                title: this.$t("global.actionfailed"),
                text: "Es ist ein Fehler aufgetreten",
              });
        })
        .catch((error) => {
          console.error("Error fetching PDF files:", error);
        })
        .finally(() => {
          this.$refs.pdfLayerDozenteneinsaetze.loading = false;
        });
    },
  },
};
</script>

<style></style>
