<template>
  <div class="mt-3">
    <div class="language-selection float-right mr">
      <b-button
        variant="outline-primary"
        class="mx-1"
        size="sm"
        @click="changeLanguage('de')"
        >DE</b-button
      >
      <b-button
        variant="outline-primary"
        class="mr-1"
        size="sm"
        @click="changeLanguage('fr')"
        >FR</b-button
      >
      <b-button
        variant="outline-primary"
        class="mr-1"
        size="sm"
        @click="changeLanguage('en')"
        >EN</b-button
      >
      <b-button
        variant="outline-primary"
        class="mr-1"
        size="sm"
        @click="changeLanguage('it')"
        >IT</b-button
      >
    </div>
    <div class="container-fluid row">
      <div
        class="col-xl-8 col-lg-12 col-md-12 col-sm-12 order-xl-1 order-lg-2 order-md-2 order-sm-2"
      >
        <div class="row mt-10-px">
          <div class="col-xl-12 pt-2">
            <div class="tile-container">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span class="float-left">{{ t("public.offer") }}</span>
                    <div 
                      class="text-center"
                    >
                      <b-button
                        v-if="totalPages > 1"
                        variant="outline-primary" 
                        size="sm"
                        @click="previousPage" 
                        :disabled="currentPage === 1"
                      ><font-awesome-icon
                            icon="fa-regular fa-chevron-left"
                        /></b-button>
                      <span>  {{ currentPage }} / {{ totalPages }}  </span>
                      <b-button 
                        v-if="totalPages > 1"
                        variant="outline-primary" 
                        size="sm"
                        @click="nextPage" 
                        :disabled="currentPage === totalPages"
                      ><font-awesome-icon
                            icon="fa-regular fa-chevron-right"
                      /></b-button>
                    </div>
                  </div>
                  <div class="body p-2">
                    <pdf
                      :src="baseUrl + '/files/' + dokument.dokument_id + '/' + dokument.name"
                      :page="currentPage"
                      @num-pages="setPageCount"
                    ></pdf>
                    <div 
                      v-if="totalPages > 1"
                      class="text-center my-2">
                      <b-button
                        variant="outline-primary" 
                        size="sm"
                        @click="previousPage" 
                        :disabled="currentPage === 1"
                      ><font-awesome-icon
                            icon="fa-regular fa-chevron-left"
                        /></b-button>
                      <span>  {{ currentPage }} / {{ totalPages }}  </span>
                      <b-button 
                        variant="outline-primary" 
                        size="sm"
                        @click="nextPage" 
                        :disabled="currentPage === totalPages"
                      ><font-awesome-icon
                            icon="fa-regular fa-chevron-right"
                        /></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="auftrag.auftragstatus.id != '19242F23FC8'"
        class="col-xl-4 col-lg-12 col-md-12 col-sm-12 order-xl-2 order-lg-1 order-md-1 order-sm-1"
      >
        <div class="row mt-10-px sticky">
          <div class="col-xl-12 pt-2">
            <div class="tile-container">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ t("public.acceptoffer") }}</span>
                  </div>
                  <div class="body">
                    <div class="row mr-2">
                      <div class="col-xl-6 col-lg-6 p-custom">
                        <b-button
                          variant="outline-success"
                          class="w-100 mx-1"
                          :class="
                            antwort == 'offeraccepted' ? 'success' : 'outline-success'
                          "
                          size="sm"
                          @click="confirmOffer('offeraccepted')"
                        >
                          <font-awesome-icon
                            icon="fa-regular fa-thumbs-up"
                            class="mr-2"
                          />
                          {{ t("public.accept") }}
                        </b-button>
                      </div>
                      <div class="col-xl-6 col-lg-6 p-custom">
                        <b-button
                          variant="outline-danger"
                          class="w-100 mx-1"
                          :class="
                            antwort == 'offerrejected' ? 'danger' : 'outline-danger'
                          "
                          size="sm"
                          @click="confirmOffer('offerrejected')"
                        >
                          <font-awesome-icon
                            icon="fa-regular fa-thumbs-down"
                            class="mr-2"
                          />
                          {{ t("public.reject") }}
                        </b-button>
                      </div>
                    </div>
                    <div class="row m-2">
                      <div class="col-xl-12">
                        <textarea
                          v-model="auftrag.bemerkungen"
                          class="form-control"
                          resize="none"
                          rows="3"
                          :placeholder="t('public.comment')"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12 p-custom">
                        <b-button
                          :disabled="!selected"
                          class="primary mx-1 float-right"
                          size="sm"
                          @click="submit()"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-check"
                            class="mr-2"
                          />
                          {{ t("public.submit") }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import PublicApi from "@/PublicAppApi";

import { apiErrorToAlert } from "@/utils/Errorhandler";
import pdf from "vue-pdf";

import page from "@/mixins/Page";

export default {
  name: "Offerte",
  components: { pdf },
  mixins: [page],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      auftrag: {},
      antwort: null,
      dokument: {},
      selected: false,
      baseUrl: window.location.origin,
      currentPage: 1,
      totalPages: 0,
      translations: {
        de: {
          public: {
            offer: "Offerte",
            accept: "Annehmen",
            reject: "Ablehnen",
            acceptoffer: "Möchten Sie die Offerte annehmen?",
            submit: "Absenden",
            comment: "Kommentar",
          },
        },
        fr: {
          public: {
            offer: "Offre",
            accept: "Accepter",
            reject: "Refuser",
            acceptoffer: "Souhaitez-vous accepter l'offre?",
            submit: "Envoyer",
            comment: "Commentaire",
          },
        },
        en: {
          public: {
            offer: "Offer",
            accept: "Accept",
            reject: "Reject",
            acceptoffer: "Do you want to accept the Offer?",
            submit: "Submit",
            comment: "Comment",
          },
        },
        it: {
          public: {
            offer: "Offerta",
            accept: "Accettare",
            reject: "Rifiuto",
            acceptoffer: "Vuole accettare l'offerta?",
            submit: "Invio",
            comment: "Commento",
          },
        },
      },
      currentLanguage: "de",
    };
  },
  created() {
    this.getAuftrag();
  },
  mounted() {},
  computed: {},
  methods: {
    getAuftrag () {
      PublicApi.get("auftrag/", {
      params: { id: this.id },
    })
      .then((response) => {
        this.auftrag = response.data;
        this.getOfferte();
      })
      .catch((e) => {
        this.$notify(apiErrorToAlert(e));
        this.ladeFehler = true;
      })
      .finally(() => {
        this.isBusy = false;
      });
    },
    getOfferte() {
      PublicApi.get("dokument/", {
        params: {
          aId: this.id,
          bez: "Offerte",
        },
      })
        .then((response) => {
          this.dokument = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    confirmOffer(value) {
      if (this.auftrag.auftragstatus.id === "175207FE388") {
        this.selected = true;
        this.antwort = value;
      } else {
        alert(
          "Die Offerte kann nicht mehr angenommen oder abgelehnt werden.\nBitte wenden Sie sich an den Offertensteller."
        );
      }
    },
    submit() {
      if (this.selected) {
        PublicApi.put("auftrag/", {}, {
          params: { 
            id: this.id,
            antwort: this.antwort,
            kommentar: this.auftrag.bemerkungen
          },
        })
          .then((response) => {
            this.auftrag = response.data;
            this.getAuftrag();
            this.selected = false;
            alert(
              "Die Offerte wurde beantwortet und kann somit nicht mehr angenommen oder abgelehnt werden.\nBitte wenden Sie sich an den Offertensteller, falls Sie Ihre Antwort ändern möchten."
            );
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      } else return
    },
    setPageCount(pageCount) {
      this.totalPages = pageCount; // Set the total number of pages in the PDF
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    changeLanguage(lang) {
      this.currentLanguage = lang;
    },
    t(key) {
      return this.translations[this.currentLanguage][key.split(".")[0]][
        key.split(".")[1]
      ];
    },
  },
};
</script>
<style scoped>
.primary {
  color: #fff !important;
  background: #124862 !important;
  border-color: #124862 !important;
}
.primary:hover {
  background: #1e6c92 !important;
}
.success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.p-custom {
  padding: 0.375rem !important;
}
.mr {
  margin-right: 4.2rem;
}
</style>
    