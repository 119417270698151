<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Korrekturbuchung</h4>
          <modal-close-button @confirmed="closeModal" />
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="beleg.belegtyp"
                  label="bezeichnung"
                  :options="belegtypen"
                  :get-option-label="(e) => `${e.bezeichnung}`"
                  placeholder=" "
                  :clearable="false"
                >
                </v-select>
                <label>Belegtyp</label>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="beleg.habenkonto"
                  label="bezeichnung"
                  :options="konten"
                  :get-option-label="
                    (k) =>
                      k && k.bezeichnung && k.kontonummer
                        ? k.kontonummer + ' / ' + k.bezeichnung
                        : ''
                  "
                  placeholder=" "
                  :clearable="false"
                >
                </v-select>
                <label>Habenkonto</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input
                  v-model.number="beleg.betrag"
                  class="form-control"
                  placeholder=" "
                />
                <label>Betrag</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="beleg.sollkonto"
                  label="bezeichnung"
                  :options="konten"
                  :get-option-label="
                    (k) =>
                      k && k.bezeichnung && k.kontonummer
                        ? k.kontonummer + ' / ' + k.bezeichnung + (k.institution ? ' / ' + k.institution : '')
                        : ''
                  "
                  placeholder=" "
                  :clearable="false"
                >
                </v-select>
                <label>Sollkonto</label>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <datepicker
                  :initial="beleg.datum"
                  @update="(val) => (beleg.datum = val)"
                  show-icon
                  date-format="dd.mm.yy"
                ></datepicker>
                <label>Datum</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="beleg.ertragskostenstelle"
                  :reduce="(eks) => eks.id"
                  label="bezeichnung"
                  :options="kostenstellen"
                  :get-option-label="
                    (k) =>
                      k && k.bezeichnung
                        ? k.kostenstellennummer + ' / ' + k.bezeichnung
                        : ''
                  "
                  placeholder=" "
                  :clearable="false"
                >
                </v-select>
                <label>Kostenstelle</label>
              </div>
            </div>
            <div class="col-12 mt-5">
              <div class="form-group">
                <textarea
                  v-model="beleg.buchungstext"
                  class="form-control"
                  rows="4"
                />
                <label>Buchungstext</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="!korrekturbuchungIsValid"
            @click="korrekturbuchungErstellen"
          >
            Korrekturbuchung erstellen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ModalCloseButton from "@/components/global/ModalCloseButton";
import Datepicker from "@/components/Datepicker.vue";
import { LADE_AUSWAHLWERTE_BELEG } from "@/store/beleg/actions.type";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  name: "KorrekturbuchungModal",

  props: {
    id: {
      type: String,
      required: false,
      default: "korrektur-buchung-modal"
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedIds: {
      type: Array,
      default: function() {
        return [];
      }
    },
    debitor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shown: false,
      multiText: false,
      beleg: {
        datum: new Date().toGermanDateString(),
        sollkonto: null,
        habenkonto: null,
        betrag: null,
        netto: null,
        brutto: null,
        buchungstext: "K / ",
        debitor: null,
        auftrag: null,
        belegtyp: { id: "17926E2017D", bezeichnung: "Korrekturbuchung" },
        waehrung: null,
        ertragskostenstelle: null,
        isKorrekturbuchung: true
      }
    };
  },
  components: {
    ModalCloseButton,
    Datepicker
  },
  watch: {
    debitor: {
      immediate: true,
      handler: function() {
        this.setDefaultWerte();
      },
      deep: true
    }
  },
  computed: {
    korrekturbuchungIsValid() {
      //wenn sollkonto und habenkonto gleich sind, oder das debitor konto in keiner der beiden konten (haben/soll) vorkommt, oder einer der beiden konti leer ist, oder betrag leer ist, oder kostenstelle leer ist, return false
      const differentKonten = this.beleg.sollkonto?.id !== this.beleg.habenkonto?.id;
      const debitorKontoInKonten = this.debitorkontoIsLinked();
      const sollkontoIsNotEmpty = this.beleg.sollkonto?.id !== null && this.beleg.sollkonto?.id !== "";
      const habenkontoIsNotEmpty = this.beleg.habenkonto?.id !== null && this.beleg.habenkonto?.id !== "";
      const betragIsNotEmpty = this.beleg.betrag !== null && this.beleg.betrag !== "";
      const kostenstelleIsNotEmpty = this.beleg.ertragskostenstelle !== null && this.beleg.ertragskostenstelle !== "";
      return differentKonten && debitorKontoInKonten && sollkontoIsNotEmpty && habenkontoIsNotEmpty && betragIsNotEmpty && kostenstelleIsNotEmpty;
    },
    konten: {
      get() {
        return this.$store.state.beleg.konten;
      }
    },
    belegtypen: {
      get() {
        return this.$store.state.beleg.belegtypen;
      }
    },
    kostenstellen: {
      get() {
        return this.$store.state.beleg.kostenstellen;
      }
    }
  },
  created() {
    if (this.konten.length === 0) {
      this.$store.dispatch(`beleg/${LADE_AUSWAHLWERTE_BELEG}`);
    }
  },
  mounted() {
    this.$bus.$on("openLoeschenModal", (id) => {
      if (this.selectedIds.length > 1) this.multiText = true;
      else if (this.multiple === true) this.multiText = true;

      if (!this.shown) this.openModal(id);

    });
  },
  methods: {
    setDefaultWerte() {
      if (this.debitor && this.debitor.konto) {
        this.beleg.habenkonto = this.debitor.konto;
      }

      if (this.debitor && this.debitor.id) {
        this.beleg.debitor = this.debitor.id;
      }

      if (this.debitor && this.debitor.auftrag) {
        this.beleg.auftrag = this.debitor.auftrag.id;
      }

      if (this.debitor && this.debitor.waehrung) {
        this.beleg.waehrung = this.debitor.waehrung;
      }
    },
    debitorkontoIsLinked() {
      return this.beleg.sollkonto?.id === this.debitor?.konto.id || this.beleg.habenkonto?.id === this.debitor.konto?.id;
    },
    openModal(id) {
      this.shown = true;

      const thisId = id ? id : this.id;

      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    korrekturbuchungErstellen() {
      if (
        this.beleg.sollkonto == null ||
        this.beleg.habenkonto == null ||
        this.beleg.betrag === "" ||
        this.beleg.belegtyp == null
      ) {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: "Fehlende Angaben im Beleg."
        });
      } else if (
        this.beleg.belegtyp.bezeichnung === "Ausgleich Mahnspesen" &&
        !(
          this.beleg.habenkonto.bezeichnung === "Debitoren" ||
          this.beleg.habenkonto.bezeichnung === "Forderungen Subventionen"
        )
      ) {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: "Kontierung überprüfen"
        });
      } else if (
        this.beleg.habenkonto.bezeichnung === this.beleg.sollkonto.bezeichnung
      ) {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: "Kontierung überprüfen"
        });
      } else {
        const json = {
          auftragId: this.debitor.auftrag.id,
          belegtypId: this.beleg.belegtyp.id,
          betrag: this.beleg.betrag,
          brutto: this.beleg.betrag,
          buchungstext: this.beleg.buchungstext,
          datum: this.beleg.datum,
          debitorId: this.debitor.id,
          ertragskostenstelleId: this.beleg.ertragskostenstelle,
          habenkontoId: this.beleg.habenkonto.id,
          isKorrekturbuchung: this.beleg.isKorrekturbuchung,
          netto: this.beleg.betrag,
          sollkontoId: this.beleg.sollkonto.id,
          waehrungId: this.beleg.waehrung.id
        };
        this.$emit("confirmedKorrekturbuchung", json);
        this.closeModal();
      }
    }
  }
};
</script>

<style></style>
