<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName"/>

    <div class="container-fluid row">
      <ValidationObserver v-slot="{ invalid }">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4 mr-2">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="
                      invalid ||
                      !rechnungsDatumValid ||
                      !validSelection ||
                      (auftrag.rechnung_an_fix &&
                        (!auftrag.strasse_nr ||
                          !auftrag.plz ||
                          !auftrag.ort ||
                          !auftrag.kanton))
                    "
                    @click="speichern"
                    @shortkey="speichern"
                    v-if="editable"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary ml-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2"/>
                    {{
                      $t("global.tolist")
                    }}
                  </button>
                  <b-dropdown variant="primary ml-2">
                    <template slot="button-content">
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      {{ $t("global.print") }}
                    </template>
                    <b-dropdown-item
                      @click="ausgebenRechnungPdfCache('INVOICE1', true)"
                      v-if="$CONST('PRINTOUTS').INVOICE1 != null"
                    >
                      {{ $CONST("PRINTOUTS").INVOICE1_LABEL }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="ausgebenRechnungPdfCache('INVOICE2', true)"
                      v-if="$CONST('PRINTOUTS').INVOICE2 != null"
                    >
                      {{ $CONST("PRINTOUTS").INVOICE2_LABEL }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="ausgebenRechnungPdfCache('INVOICE3', true)"
                      v-if="$CONST('PRINTOUTS').INVOICE3 != null"
                    >
                      {{ $CONST("PRINTOUTS").INVOICE3_LABEL }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="ausgebenRechnungPdfCache('INVOICE4', true)"
                      v-if="$CONST('PRINTOUTS').INVOICE4 != null"
                    >
                      {{ $CONST("PRINTOUTS").INVOICE4_LABEL }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="ausgebenRechnungPdfCache('INVOICE5', true)"
                      v-if="$CONST('PRINTOUTS').INVOICE5 != null"
                    >
                      {{ $CONST("PRINTOUTS").INVOICE5_LABEL }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="ausgebenRechnungPdfCache('INVOICE6', true)"
                      v-if="$CONST('PRINTOUTS').INVOICE6 != null"
                    >
                      {{ $CONST("PRINTOUTS").INVOICE6_LABEL }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="ausgebenRechnungPdfCache('INVOICE7', true)"
                      v-if="$CONST('PRINTOUTS').INVOICE7 != null"
                    >
                      {{ $CONST("PRINTOUTS").INVOICE7_LABEL }}
                    </b-dropdown-item>
                    <!--Carbone-->
                    <b-dropdown-item
                      v-for="carboneprint in validCarbonePrintouts(
                        $CONST('CARBONEPRINTOUTS')
                      )"
                      :key="carboneprint.id"
                      @click="
                        carbone(
                          carboneprint.category.bezeichnung,
                          carboneprint.template,
                          'carbone-finanzen/?ids='
                        )
                      "
                    >
                      {{ carboneprint.value }}
                    </b-dropdown-item>
                    <!--Ende Carbone-->
                    <b-dropdown-item
                      :disabled="
                        (personId && auftrag.konto) ||
                        (firmaId && auftrag.konto)
                          ? false
                          : true
                      "
                      @click="ausgebenKopiekostenPdfCache('INVOICE8', true)"
                      v-if="
                        (baseURL =
                          'https://tsp.bx-education.ch' &&
                          $CONST('PRINTOUTS').INVOICE8 != null)
                      "
                    >
                      <span>{{ $CONST("PRINTOUTS").INVOICE8_LABEL }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="ausgebenRechnungPdfCache('INVOICE9', true)"
                      v-if="$CONST('PRINTOUTS').INVOICE9 != null"
                    >
                      {{ $CONST("PRINTOUTS").INVOICE9_LABEL }}
                    </b-dropdown-item>
                  </b-dropdown>

                  <button
                    class="btn btn-primary ml-2"
                    v-if="berechtigungen.m_finanzen.create"
                    @click="rechnungKopierenModal"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-print"
                      class="mr-2"
                    />
                    {{ $t("global.copyinvoice") }}
                  </button>
                </div>

                <div class="mr-4">
                  <button
                    class="btn btn-secondary mr-2"
                    @click="speichern"
                    v-if="isAdmin && !editable"
                  >
                    {{ $t("global.updateimport") }}
                  </button>

                  <button
                    v-if="berechtigungen.m_finanzen.delete"
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    :disabled="!editable"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row col-xl-12 pr-0">
          <!-- START Linke Seite -->
          <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
            <!-- START Stammdaten -->
            <container-headline
              :headline="$t('global.addressinformation')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-6">
                      <single-select-firma
                        id="auftragFirma"
                        :firma="auftrag.firma"
                        :editable="editable"
                        :allowOpen="true"
                        :label="$t('global.company')"
                        :required="!validSelection"
                        @confirmed="setFirma"
                      />
                    </div>
                    <div class="col-xl-6">
                      <single-select-person
                        id="auftragPerson"
                        :person="auftrag.person"
                        :firma="auftrag.firma"
                        :allowOpen="true"
                        :editable="editable"
                        @confirmed="setPerson"
                        @updatefirma="setFirma"
                        :required="!validSelection"
                        :label="$t('global.person')"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="auftrag.rechnungsadresse"
                          label="adresseKomplett"
                          :options="adressenVerbObjects"
                          :reduce="(s) => s.id"
                          :placeholder="$t('global.invoiceaddress')"
                          :clearable="true"
                          :filterable="false"
                          :disabled="!editable || auftrag.rechnung_an_fix"
                        >
                          <template #no-options>
                            {{ $t("global.noaddressesfound") }}
                          </template>
                        </v-select>
                        <label>{{ $t("global.addresses") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          type="checkbox"
                          class="mr-2"
                          v-model="auftrag.rechnung_an_fix"
                          @change="resetAdresse()"
                          :disabled="!editable"
                        />
                        {{ $t("global.manualinvoiceaddress") }}
                      </div>
                    </div>
                    <div class="col-xl-12" v-show="auftrag.rechnung_an_fix">
                      <div class="form-group">
                        <input
                          v-model.trim="auftrag.strasse_nr"
                          type="text"
                          class="form-control"
                          placeholder=" "
                          :readonly="!auftrag.rechnung_an_fix"
                          @input="updateRechnungAn"
                        />
                        <label
                          v-bind:class="
                            auftrag.rechnung_an_fix && !auftrag.strasse_nr
                              ? 'text-danger'
                              : ''
                          "
                        >{{ $t("global.streetandhousenumber") }}</label
                        >
                      </div>
                      <div class="row">
                        <div class="col-xl-3">
                          <div class="form-group">
                            <input
                              v-model.trim="auftrag.plz"
                              type="text"
                              class="form-control"
                              placeholder=" "
                              :readonly="!auftrag.rechnung_an_fix"
                              @input="updateRechnungAn"
                            />
                            <label
                              v-bind:class="
                                auftrag.rechnung_an_fix && !auftrag.plz
                                  ? 'text-danger'
                                  : ''
                              "
                            >{{ $t("global.zipcode") }}</label
                            >
                          </div>
                        </div>
                        <div class="col-xl-7">
                          <div class="form-group">
                            <input
                              v-model.trim="auftrag.ort"
                              type="text"
                              class="form-control"
                              placeholder=" "
                              :readonly="!auftrag.rechnung_an_fix"
                              @input="updateRechnungAn"
                            />
                            <label
                              v-bind:class="
                                auftrag.rechnung_an_fix && !auftrag.ort
                                  ? 'text-danger'
                                  : ''
                              "
                            >{{ $t("global.city") }}</label
                            >
                          </div>
                        </div>
                        <div class="col-xl-2">
                          <div class="form-group">
                            <input
                              v-model.trim="auftrag.kanton"
                              type="text"
                              class="form-control"
                              placeholder=" "
                              :readonly="!auftrag.rechnung_an_fix"
                              @input="updateRechnungAn"
                            />
                            <label
                              v-bind:class="
                                auftrag.rechnung_an_fix && !auftrag.kanton
                                  ? 'text-danger'
                                  : ''
                              "
                            >{{ $t("global.canton") }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                  >
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.trim="auftrag.rechnungadresszusatz"
                          class="form-control"
                          placeholder=" "
                          readonly
                        />
                        <label>{{
                            $t("global.rgaddressadditionfromregistration")
                          }}</label>
                      </div>
                    </div>

                    <div class="col-xl-6">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <div class="form-group">
                          <input
                            v-model.trim="auftrag.empfaenger"
                            class="form-control"
                            placeholder=" "
                            :readonly="!editable"
                          />
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.receiver")
                            }}</label>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model="auftrag.rechnung_an"
                          class="form-control"
                          placeholder=" "
                          rows="5"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.invoiceto") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <container-headline
              :headline="$t('global.additionalinformation')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="auftrag.zahlungskondition"
                          label="bezeichnung"
                          :options="zahlungskonditionen"
                          :placeholder="$t('global.termsofpayment')"
                          :clearable="false"
                          :disabled="!editable"
                          @input="updateZahlungsziel"
                        >
                        </v-select>
                        <label>{{ $t("global.termsofpayment") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <v-select
                            v-model="auftrag.zahlungsplan"
                            label="bezeichnung"
                            v-bind:class="{ 'border-info': !passed }"
                            :options="zahlungsplaene"
                            :placeholder="$t('global.paymentschedule')"
                            :clearable="false"
                            :disabled="!editable"
                          >
                          </v-select>
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.paymentschedule")
                            }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group v-select-fit-content">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <v-select
                            v-model="auftrag.konto"
                            :get-option-label="
                            (k) =>
                              k && k.bezeichnung && k.kontonummer
                                ? k.kontonummer +
                                  ' / ' +
                                  k.bezeichnung +
                                  (k.institution ? ' / ' + k.institution : '')
                                : ''
                              "
                            v-bind:class="{ 'border-info': !passed }"
                            :options="konten"
                            :placeholder="$t('global.account')"
                            :clearable="false"
                            :disabled="!editable"
                          >
                          </v-select>
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.account")
                            }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <container-headline
              :headline="$t('global.Amount')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <v-select
                            v-model="auftrag.waehrung"
                            label="bezeichnung"
                            v-bind:class="{ 'border-info': !passed }"
                            :options="waehrungen"
                            :placeholder="$t('global.currency')"
                            :clearable="false"
                            :disabled="!editable"
                          >
                          </v-select>
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.currency")
                            }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <input
                          :value="bruttoAnzeige"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.totalgross") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <input
                          :value="nettoAnzeige"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.totalnet") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <input
                          :value="mwstAnzeige"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.totalvat") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ENDE Stammdaten -->
          </div>
          <!-- ENDE linke Seite -->

          <!-- START Rechte Seite -->
          <div class="col-xl-6 pl-4 pr-0" :class="{ 'col-xl-12': !showNavbar }">
            <container-headline
              :headline="$t('global.orderdata')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <input
                          v-model.number="auftrag.rechnungprefix"
                          class="form-control"
                          placeholder=" "
                          :disabled="!editable"
                        />
                        <label>{{ $t("global.prefix") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4" v-if="id">
                      <div class="form-group">
                        <input
                          v-model.number="auftrag.auftragsnummer"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.ordernum") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="auftrag.auftragsstatus"
                          label="bezeichnung"
                          :options="auftragsstati"
                          :placeholder="$t('global.statusorder')"
                          :clearable="false"
                          :disabled="!editable"
                        >
                        </v-select>
                        <label>{{ $t("global.statusorder") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <!--<v-select
                          v-model="auftrag.buchungsstatus"
                          label="bezeichnung"
                          :options="buchungsstati"
                          placeholder="Status Buchung"
                          :clearable="false"
                          disabled="!editable"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"></span>
                          </template>
                        </v-select>
                        ersetzt weil ein Editieren keinen Sinn macht (Es wird keine Buchung ausgeführt) -->
                        <input
                          v-model.number="auftrag.buchungsstatus.bezeichnung"
                          class="form-control"
                          readonly
                        />
                        <label>{{ $t("global.statusbooking") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="auftrag.quelleAuftrag"
                          label="bezeichnung"
                          :options="auftragsquellen"
                          placeholder="Quelle"
                          :clearable="false"
                          :disabled="!editable"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"></span>
                          </template>
                        </v-select>
                        <label>{{ $t("global.source") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="auftrag.rechnungsdatum || ''"
                          placeholer=" "
                          :show-icon="true"
                          @update="checkDate"
                          :disabled="!editable"
                        />
                        <label>{{ $t("global.invoicedate") }}</label>
                      </div>
                      <label
                        v-if="!rechnungsDatumValid"
                        class="validation-warning"
                      >{{
                          $t(
                            "global.notification_invoicedatemustnotbeinclosedyear"
                          )
                        }}</label
                      >
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="auftrag.rechnungVersendet"
                          placeholer=" "
                          :show-icon="true"
                          @update="(val) => (auftrag.rechnungVersendet = val)"
                          :disabled="!editable"
                        />
                        <label>{{ $t("global.invoicesent") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="auftrag.auftragsdatum"
                          placeholder=" "
                          :show-icon="true"
                          @update="(val) => (auftrag.auftragsdatum = val)"
                          :disabled="!editable"
                        />
                        <label>{{ $t("global.orderdate") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="auftrag.verbuchtAm"
                          placeholder=" "
                          :show-icon="true"
                          @update="(val) => (auftrag.verbuchtAm = val)"
                          :disabled="true"
                        />
                        <label>{{ $t("global.postedon") }}</label>
                      </div>
                    </div>

                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="auftrag.zahlungsverbindung"
                          label="bezeichnung"
                          :options="zahlungsverbindungen"
                          :placeholder="$t('global.paymentdetails')"
                          :clearable="false"
                          :disabled="!editable"
                        >
                        </v-select>
                        <label>{{ $t("global.paymentdetails") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <single-select-debitor
                        :debitor="debitor"
                        :allowOpen="true"
                        :editable="false"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="auftrag.buchungsstatus.id == '1751D1D2993'"
                  >
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model="auftrag.stornierungsgrund"
                          placeholder=""
                          rows="3"
                          class="form-control"
                          :readonly="!editable"
                        ></textarea>
                        <label>{{ $t("global.reasonforcancellation") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <container-headline
              :headline="$t('global.invoiceinformation')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-6">
                      <single-select-person
                        id="auftragKontakt"
                        :person="auftrag.unserKontakt"
                        :allowOpen="true"
                        :editable="true"
                        :required="false"
                        @confirmed="setKontakt"
                        :label="$t('global.ourcontact')"
                      />
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          class="form-control"
                          v-model="auftrag.ihre_referenz"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.yourreference") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model="auftrag.einleitungstext"
                          class="form-control"
                          placeholder=" "
                          rows="2"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.introductiontext") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model="auftrag.schlusstext"
                          class="form-control"
                          placeholder=" "
                          rows="2"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.closingtext") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model="auftrag.bemerkungen"
                          class="form-control"
                          placeholder=""
                          rows="2"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.remarks") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model="auftrag.referenznummer"
                          class="form-control"
                          placeholder=" "
                          :readonly="true"
                        />
                        <label>{{ $t("global.referencenumber") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <container-headline headline="Gutscheine" :col="6"></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row mt-2 mb-3">
                <div class="col-xl-6"></div>
              </div>
            </div>
          </div>
        </div> -->
          </div>
        </div>
        <unsaved-changes-modal
          @discard="handleDiscard"
          @stay="handleStay"
          :saveFunction="
            invalid ||
            !rechnungsDatumValid ||
            !validSelection ||
            (auftrag.rechnung_an_fix &&
              (!auftrag.strasse_nr ||
                !auftrag.plz ||
                !auftrag.ort ||
                !auftrag.kanton))
              ? null
              : speichern
          "
        />
      </ValidationObserver>

      <div v-if="auftrag && id" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 p-0">
            <ul class="nav nav-tabs mt-3 fs-28">
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 0 }"
                  :to="{
                    name: 'auftrag.id.anzeige',
                    params: { id: id, anzeige: 0 },
                  }"
                >{{ $t("global.orderitems") }}
                </router-link
                >
              </li>
              <!--
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 1 }"
                  :to="{
                    name: 'auftrag.id.anzeige',
                    params: { id: id, anzeige: 1 },
                  }"
                  >Zahlungsrate</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 2 }"
                  :to="{
                    name: 'auftrag.id.anzeige',
                    params: { id: id, anzeige: 2 },
                  }"
                  >Email</router-link
                >
              </li>
              -->
            </ul>
          </div>
          <div class="col-xl-12 p-0">
            <div class="tab-container p-3">
              <div v-show="anzeige === 0">
                <auftragspositionen
                  :id="id"
                  :auftrag="auftrag"
                  :editable="editable"
                  :shown="anzeige == 0 ? true : false"
                  @positionen-changed="ladeAuftrag(id)"
                  @positionen-loaded="setPositionen"
                  :key="positionenKey"
                />
              </div>
              <div v-show="anzeige === 1"></div>

              <div v-show="anzeige === 2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
    <rechnungsplit-modal
      ref="RechnungsplitModal"
      id="rechnungsplit-modal"
      :auftrag="auftrag"
      :positionen="auftrag.positionen"
      @confirmed="reloadAuftrag"
    ></rechnungsplit-modal>
    <loeschen-modal id="modal-loeschen" @confirmed="rechnungLoeschen"/>
    <message-box
      id="rechnung-kopieren-modal"
      :headerText="$t('global.copyinvoice')"
      :text="$t('global.doyoureallywanttocopyinvoice')"
      :ok="true"
      :cancel="true"
      :repeat="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
      @ok="kopiereRechnung"
    />
    <message-box
      id="in-kopierter-rechnung-modal"
      :headerText="$t('global.invoicecopied')"
      :text="$t('global.youareinthecopiedinvoice')"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
    />
    <pdf-layer
      id="pdf-layer-finanzen"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :auftrag="auftrag"
      :person="auftrag.person"
      :firma="auftrag.firma"
      :files="pdfFiles"
    ></pdf-layer>
  </div>
</template>

<script>
import page from "@/mixins/Page";
import CarboneApi from "@/CarboneApi";
import {apiErrorToAlert} from "@/utils/Errorhandler";
import DatePicker from "@/components/Datepicker";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import SingleSelectDebitor from "@/components/global/SingleSelectDebitor.vue";
import HeadMenu from "@/components/HeadMenu";
import RechnungsplitModal from "@/components/Auftrag/RechnungsplitModal";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import Auftragspositionen from "@/components/Auftrag/Auftragspositionen";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MessageBox from "@/components/Modals/Messagebox";

import {LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER} from "@/store/geschaeftspartner/actions.type";
import {LADE_AUSWAHLWERTE_AUFTRAG} from "@/store/auftrag/actions.type";
import {LADE_FINANZ_INFORMATIONEN} from "@/store/finanzen/actions.type";

import Api from "@/Api";
import numeral from "numeral";
import server from "@/server";

import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

export default {
  name: "Rechnung",
  components: {
    DatePicker,
    HeadMenu,
    ContainerHeadline,
    Navbar,
    LoadingOverlay,
    Auftragspositionen,
    RechnungsplitModal,
    SingleSelectPerson,
    LoeschenModal,
    SingleSelectDebitor,
    MessageBox,
    SingleSelectFirma,
    UnsavedChangesModal,
  },
  mixins: [page, ChangeWatcherNew],
  props: {
    id: {
      type: String,
      default: "",
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      baseURL: this.$CONST("CONFIG").BASEURL,
      auftrag: {
        rechnungprefix: this.$CONST("CONFIG").RGNOPREFIX,
        rechnungsadresse: {},
        waehrung: {},
        unserKontakt: {personName: this.$t("global.notlinked")},
        positionen: [],
        person: {personName: this.$t("global.notlinked")},
        firma: {name: this.$t("global.notlinked")},
        buchungsstatus: {},
        auftragsstatus: {},
        zahlungsverbindung: {},
        quelleAuftrag: {},
        zahlungskondition: {},
        strasse_nr: "",
        ort: "",
        plz: "",
        kanton: null,
        rechnung_an_fix: false,
        rechnung_an: null,
      },
      isAdmin: false,
      positionen: [],
      debitor: {},
      personId: "",
      firmaId: "",
      gpChanged: false,
      personen: [],
      adressenVerbObjects: [],
      adressen: [],
      loading: false,
      rechnungsadresse: {strasse_nr: ""},
      copyAuftragId: null,
      zahlungskondistionenText: "",
      kontotext: "",
      kontonummer: null,
      positionenKey: 0,
      alertModal: {
        color: null,
        text: "",
        title: "",
      },
      aeltestesOffenesGeschaeftsjahr: 4000,
      rechnungsDatumValid: true,
      editable: true,
      pdfFileName: "",
      pdfPath: "",
      pdfFiles: [],
    };
  },
  computed: {
    validSelection() {
      if (this.auftrag.person?.id || this.auftrag.firma?.id) {
        return true;
      } else {
        return false;
      }
    },
    postleitzahlen: {
      get() {
        return this.$store.state.geschaeftspartner.postleitzahlen;
      },
    },
    geschaeftsjahre: {
      get() {
        return this.$store.state.finanzen.geschaeftsjahre;
      },
    },
    geschaeftsjahr: {
      get() {
        return this.$store.state.finanzen.geschaeftsjahr;
      },
    },
    auftragsstati: {
      get() {
        return this.$store.state.auftrag.auftragsstati;
      },
    },
    buchungsstati: {
      get() {
        return this.$store.state.auftrag.buchungsstati;
      },
    },
    auftragsquellen: {
      get() {
        return this.$store.state.auftrag.quellen;
      },
    },
    zahlungskonditionen: {
      get() {
        return this.$store.state.auftrag.zahlungskonditionen;
      },
    },
    zahlungsplaene: {
      get() {
        return this.$store.state.auftrag.zahlungsplaene;
      },
    },
    konten: {
      get() {
        return this.$store.state.auftrag.konten;
      },
    },
    waehrungen: {
      get() {
        return this.$store.state.auftrag.waehrungen;
      },
    },
    zahlungsverbindungen: {
      get() {
        return this.$store.state.auftrag.zahlungsverbindungen;
      },
    },
    navbarTitel: function () {
      if (this.id) {
        return `${this.$t("global.order")} ${this.auftrag.auftragsnummer}`;
      } else {
        return this.$t("global.neworder");
      }
    },

    waehrungBezeichnung() {
      if (this.auftrag.waehrung) return this.auftrag.waehrung.bezeichnung;
      return "-";
    },
    nettoAnzeige() {
      if (this.auftrag.totalNetto)
        return numeral(this.auftrag.totalNetto).format("#,##0.00");
      return numeral(0).format("#,##0.00");
    },
    mwstAnzeige() {
      if (this.auftrag.totalMwst)
        return numeral(this.auftrag.totalMwst).format("#,##0.00");
      return numeral(0).format("#,##0.00");
    },
    bruttoAnzeige() {
      if (this.auftrag.totalBrutto)
        return numeral(this.auftrag.totalBrutto).format("#,##0.00");
      return numeral(0).format("#,##0.00");
    },
  },
  watch: {
    ["auftrag.rechnung_an_fix"]() {
      if (this.auftrag.rechnung_an_fix == true) {
        this.$set(
          this.auftrag,
          "rechnungsadresseManuelId",
          this.auftrag?.rechnungsadresse_manuel?.id
        );
        this.updateRechnungAn();
      } else {
        this.auftrag.rechnung_an = "";
      }
    },
    ["auftrag.plz"]() {
      if (!this.auftrag.ort) {
        let ort = this.postleitzahlen.find(
          (plz) => plz.plz == this.auftrag.plz
        );
        if (ort != null) this.auftrag.ort = ort.ort;
      }
      if (!this.auftrag.kanton) {
        let kanton = this.postleitzahlen.find(
          (plz) => plz.plz == this.auftrag.plz
        );
        if (kanton != null) this.auftrag.kanton = kanton.kanton;
      }
    },
    ["auftrag.ort"]() {
      if (this.auftrag.plz != null && this.auftrag.plz != "") return;
      if (this.auftrag.ort) return;

      let plz = this.postleitzahlen.find((plz) => plz.ort == this.auftrag.ort);
      if (plz != null) this.auftrag.plz = plz.plz;
    },
    /*     ["auftrag.zahlungskondition"]: {
      handler: function (val) {
        if (val) {
          if (!this.auftrag.rechnungsdatum)
            this.auftrag.rechnungsdatum = new Intl.DateTimeFormat("de-CH", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date());

          var parts = this.auftrag.rechnungsdatum.match(/(\d+)/g);
          let rgDatum = new Date(parts[2], parts[1] - 1, parts[0]).addDays(
            val.tage
          );

          this.auftrag.zahlungsziel = new Intl.DateTimeFormat("de-CH").format(
            rgDatum
          );
        }
      },
    },
    ["auftrag.rechnungsdatum"]: {
      handler: function (val) {
        if (val && this.auftrag.zahlungskondition) {
          var parts = this.auftrag.rechnungsdatum.match(/(\d+)/g);
          let rgDatum = new Date(parts[2], parts[1] - 1, parts[0]).addDays(
            this.auftrag.zahlungskondition.tage
          );

          this.auftrag.zahlungsziel = new Intl.DateTimeFormat("de-CH").format(
            rgDatum
          );
        }
      },
    }, */
    ["auftrag.rechnungsadresse"]: {
      handler: function (val) {
        if (val && !this.auftrag.rechnung_an_fix) {
          let id = val.id ? val.id : val;
          let newAddress = this.adressenVerbObjects.find(
            (adresse) => adresse.id == id
          );

          if (newAddress) {
            if (this.auftrag.adresszusatz) {
              newAddress.adresse_label =
                this.auftrag.adresszusatz + "\n" + newAddress.adresse_label;
            }
            let label = newAddress.adresse_label.split("\n");

            /*if (
              this.auftrag.rechnungadresszusatz &&
              this.auftrag.rechnungadresszusatz != ""
            ) {
              if (newAddress.firma)
                label.splice(1, 0, [this.auftrag.rechnungadresszusatz]);
              else label.splice(0, 0, [this.auftrag.rechnungadresszusatz]);
            }*/

            let adressierung = "";
            let firmaAnschrift = "";
            let personAnschrift = "";

            if (this.auftrag.firma?.id) {
              if (this.auftrag.person?.id) {
                if (this.auftrag.person.rechnunganohneperson == false)
                  firmaAnschrift += "\n";
              }
              firmaAnschrift += this.auftrag.firma.name;
            } else {
              firmaAnschrift = "";
            }

            if (
              this.auftrag.person != null &&
              this.auftrag.person.geschlecht != null
            ) {
              personAnschrift +=
                this.auftrag.person.geschlecht.id ==
                this.$CONST("GESCHLECHTER").MALE
                  ? this.$t("global.mr")
                  : this.$t("global.ms");
            } else {
              personAnschrift = "";
            }
            if (this.auftrag.person?.id) {
              personAnschrift +=
                " " +
                this.auftrag.person.vorname +
                " " +
                this.auftrag.person.name;
            } else {
              personAnschrift = "";
            }

            if (firmaAnschrift == "" && personAnschrift != "") {
              adressierung = personAnschrift;
            } else if (firmaAnschrift != "" && personAnschrift == "") {
              adressierung = firmaAnschrift;
            } else {
              adressierung =
                (!this.person.rechnunganohneperson
                  ? this.auftrag.firma.name + "\n"
                  : "") + personAnschrift;
            }

            if (adressierung) {
              label.splice(0, 0, [adressierung]);
            }

            this.auftrag.rechnung_an = label.join("\n");
          }
        }
      },
      immediate: true,
    },
    konto(val) {
      let konto = this.konten.find((k) => k.id === val);
      if (konto) {
        this.kontotext = konto.bezeichnung;
        this.kontonummer = konto.nummer;
      }
    } /*
    adresseKomplett(val) {
      this.auftrag.rechnungAn = val.rechnungAn;
    },*/,

    /*unserKontaktId(val) {
      if (val) {
        let person = this.personen.find((e) => e.id === val);
        if (person !== undefined) this.unserKontakt = person;
      } else {
        this.unserKontakt = null;
      }
    },*/

    // DEFAULT WERTE SETZEN

    auftragsquellen: {
      handler: function (val) {
        if (
          val &&
          (!this.auftrag.quelleAuftrag || !this.auftrag.quelleAuftrag.id)
        ) {
          this.setzeDefaultWerte();
        }
      },
    },
  },
  created() {
    if (
      this.auftragsstati.length == 0 ||
      this.buchungsstati.length == 0 ||
      this.auftragsquellen.length == 0
    )
      this.$store.dispatch(`auftrag/${LADE_AUSWAHLWERTE_AUFTRAG}`);

    if (this.geschaeftsjahre.length == 0)
      this.$store.dispatch(`finanzen/${LADE_FINANZ_INFORMATIONEN}`);

    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  mounted() {
    this.$store.dispatch(
      `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
    );
    if (this.id) {
      try {
        this.ladeAuftrag(this.id);
      } catch (e) {
        alert(e);
      }
    } else {
      if (this.auftragsstati.length > 0) this.setzeDefaultWerte();
    }
  },
  methods: {
    validCarbonePrintouts(constant) {
      const validCarbonePrintouts = [];

      for (const key in constant) {
        const carboneprint = constant[key];
        if (this.isValidCarbonePrintout(carboneprint)) {
          validCarbonePrintouts.push(carboneprint);
        }
      }

      return validCarbonePrintouts;
    },
    carbone(category, templateID, endpoint) {
      if (!this.id) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });
      } else {
        CarboneApi.get(
          endpoint +
          this.id +
          "&category=" +
          category +
          "&templateId=" +
          templateID
        )
          .then((response) => {
            this.pdfFiles = response.data.map((item) => ({
              url: server.url + item.url,
              name: item.name,
              count: item.count,
              size: item.name,
            }));
            this.pdfFiles.length > 0
              ? this.$refs.pdfLayer.show()
              : this.$notify({
                type: "warn",
                title: this.$t("global.actionfailed"),
                text: "Es ist ein Fehler aufgetreten",
              });
          })
          .catch((error) => {
            console.error("Error fetching PDF files:", error);
          })
          .finally(() => {
            this.$refs.pdfLayer.loading = false;
          });
      }
    },
    getAdressierung() {
      let adressierung = "";
      let firmaAnschrift = "";
      let personAnschrift = "";

      if (this.auftrag.firma?.id) {
        firmaAnschrift += this.auftrag.firma.name;
      } else {
        firmaAnschrift = "";
      }

      if (
        this.auftrag.person != null &&
        this.auftrag.person.geschlecht != null
      ) {
        personAnschrift +=
          this.auftrag.person.geschlecht.id == this.$CONST("GESCHLECHTER").MALE
            ? this.$t("Herr")
            : this.$t("Frau");
      } else {
        personAnschrift = "";
      }
      if (this.auftrag.person?.id) {
        personAnschrift +=
          " " + this.auftrag.person.vorname + " " + this.auftrag.person.name;
      } else {
        personAnschrift = "";
      }

      if (firmaAnschrift == "" && personAnschrift != "") {
        adressierung = personAnschrift;
      } else if (firmaAnschrift != "" && personAnschrift == "") {
        adressierung = firmaAnschrift;
      } else {
        adressierung =
          (!this.person?.rechnunganohneperson ? firmaAnschrift : "") +
          "\n" +
          personAnschrift;
      }

      return adressierung;
    },
    //Überprüft ob das Rechnungsdatum in einem offenen Geschäftsjahr liegt
    checkDate(val) {
      if (this.geschaeftsjahre.length != 0) {
        let datumTemp;

        //Setzt das aelteste offene Geschaeftsjahr
        if (this.aeltestesOffenesGeschaeftsjahr == 4000) {
          this.geschaeftsjahre.forEach((jahr) => {
            if (jahr.geschaeftsjahrstatus.id === "1756932CE8E") {
              datumTemp = jahr.ende.substring(6, 10);
              if (parseInt(datumTemp) < this.aeltestesOffenesGeschaeftsjahr) {
                this.aeltestesOffenesGeschaeftsjahr = parseInt(datumTemp);
              }
            }
          });
        }

        datumTemp = val.substring(6, 10);

        if (parseInt(datumTemp) >= this.aeltestesOffenesGeschaeftsjahr) {
          this.rechnungsDatumValid = true;
          this.auftrag.rechnungsdatum = val;
        } else {
          this.rechnungsDatumValid = false;
        }

        this.updateZahlungsziel();
      }
    },

    resetAdresse() {
      if (this.auftrag.rechnung_an_fix) {
        //this.auftrag.rechnungAn = null; Würde Editbox leer machen wenn vorher was gewählt ist. Tendentiell Werte drin lassen
        this.auftrag.rechnungsadresse = null;
        this.updateRechnungAn();
      }
    },
    setzeDefaultWerte() {
      this.auftrag.waehrung = {id: "174E4563A31", bezeichnung: "CHF"}; // TODO: das muss schöner gemacht werden
      this.auftrag.unserKontakt = this.angemeldeterMitarbeiter.person;
      this.editable = this.berechtigungen.m_finanzen.update;

      this.auftrag.quelleAuftrag = this.auftragsquellen[0];
      this.auftrag.zahlungsverbindung = this.zahlungsverbindungen.find(
        (zv) => zv.id === this.$CONST("CONFIG").PAYMENTTARGET
      );
      this.auftrag.zahlungsplan = this.zahlungsplaene.find(
        (zp) => zp.id === this.$CONST("CONFIG").PAYMENTPLAN
      );
      this.auftrag.auftragsstatus = this.auftragsstati.find(
        (as) => as.id === this.$CONST("AUFTRAGSTATI").INVOICE
      );
      this.auftrag.buchungsstatus = this.buchungsstati.find(
        (bs) => bs.id == this.$CONST("BUCHUNGSTATI").NOTBOOKED
      );
      this.auftrag.zahlungskondition = this.zahlungskonditionen.find(
        (bs) => bs.id == this.$CONST("CONFIG").PAYMENTCONDITIONS
      );
      this.auftrag.konto = this.konten.find(
        (k) => k.id == this.$CONST("CONFIG").PAYMENTACCOUNT
      );
    },

    async ladeAuftrag() {
      if (this.auftrag?.id) {
        let response = await Api.get("auftrag/", {
          params: {id: this.auftrag.id},
        });
        this.initializeAuftrag(response.data);
      } else {
        let response = await Api.get("auftrag/", {
          params: {id: this.id ? this.id : this.auftrag.id},
        });
        this.initializeAuftrag(response.data);
      }
    },

    initializeAuftrag(auftrag) {
      if (auftrag) {
        this.auftrag = auftrag;

        if (auftrag.person?.id) this.personId = auftrag.person.id;

        if (auftrag.firma?.id) this.firmaId = auftrag.firma.id;

        //rechnungsadresse manuell
        this.$set(
          this.auftrag,
          "ort",
          this.auftrag.rechnungsadresse_manuel?.ort
            ? this.auftrag.rechnungsadresse_manuel?.ort
            : ""
        );
        this.$set(
          this.auftrag,
          "plz",
          this.auftrag.rechnungsadresse_manuel?.plz
            ? this.auftrag.rechnungsadresse_manuel?.plz
            : ""
        );
        this.$set(
          this.auftrag,
          "kanton",
          this.auftrag.rechnungsadresse_manuel?.kanton
            ? this.auftrag.rechnungsadresse_manuel?.kanton
            : ""
        );
        this.$set(
          this.auftrag,
          "strasse_nr",
          this.auftrag.rechnungsadresse_manuel?.strasse_nr
            ? this.auftrag.rechnungsadresse_manuel?.strasse_nr
            : ""
        );

        if (this.auftrag.rechnung_an_fix == true) {
          this.$set(
            this.auftrag,
            "rechnungsadresseManuelId",
            this.auftrag?.rechnungsadresse_manuel?.id
          );
          if (!this.auftrag.rechnung_an) this.updateRechnungAn();
        }
        if (
          this.auftrag.rechnungsadresse &&
          this.auftrag.rechnung_an_fix == false
        )
          this.rechnungsadresse = this.auftrag.rechnungsadresse;

        if (this.auftrag.rechnung_an_fix == true) {
          this.auftrag.rechnungsadresseManuelId =
            this.auftrag.rechnungsadresse_manuel.id;
          //rechnungsadresse manuel
          this.auftrag.ort = this.auftrag.rechnungsadresse_manuel.ort;
          this.auftrag.plz = this.auftrag.rechnungsadresse_manuel.plz;
          this.auftrag.kanton = this.auftrag.rechnungsadresse_manuel.kanton;
          this.auftrag.strasse_nr =
            this.auftrag.rechnungsadresse_manuel.strasse_nr;
        }

        if (this.auftrag.person?.id) {
          this.person = this.auftrag.person;
          this.personId = this.auftrag.person.id;
        } else auftrag.person = {personName: this.$t("global.notlinked")};

        if (this.auftrag.firma?.id) {
          this.firma = this.auftrag.firma;
          this.firmaId = this.auftrag.firma.id;
        } else auftrag.firma = {name: this.$t("global.notlinked")};

        if (!auftrag.auftragsstatus)
          auftrag.auftragsstatus = {bezeichnung: this.$t("global.notlinked")};

        if (!auftrag.quelleAuftrag)
          auftrag.quelleAuftrag = {bezeichnung: this.$t("global.notlinked")};

        /*  if (!auftrag.waehrung)
          auftrag.waehrung = { bezeichnung: this.$t("global.notlinked") }; */

        if (!auftrag.unserKontakt)
          auftrag.unserKontakt = {personName: this.$t("global.notlinked")};

        if (auftrag.empfaenger.includes("\n")) {
          //auftrag.empfaenger.replaceAll("\n", ", ");
          auftrag.empfaenger = auftrag.empfaenger.replace(/\n/, " ");
        }

        if (this.auftrag.person.id && this.auftrag.firma.id) {
          this.ladeAdressen(this.auftrag.person.id, this.auftrag.firma.id);
        } else if (this.auftrag.person.id && !this.auftrag.firma.id) {
          this.ladeAdressen(this.auftrag.person.id, null);
        } else if (!this.auftrag.person.id && this.auftrag.firma.id) {
          this.ladeAdressen(null, this.auftrag.firma.id);
        }

        Api.get("finanzen/zahlschuld/", {
          params: {auftragid: this.auftrag.id},
        }).then((response) => {
          if (response.data) {
            this.debitor = response.data;
          }
        });
        if (
          (this.auftrag.buchungsstatus &&
            this.auftrag.buchungsstatus.bezeichnung ==
            this.$t("global.bookedpay")) ||
          (this.auftrag.buchungsstatus &&
            this.auftrag.buchungsstatus.bezeichnung ==
            this.$t("global.canceled"))
        ) {
          this.editable = false;
        } else {
          /*
        else if (!this.geschaeftsjahr) {
          this.editable = false;
        }*/
          if (this.auftrag.rechnungsdatum) {
            this.editable =
              this.auftrag.auftragsstatus.id ==
              this.$CONST("AUFTRAGSTATI").DRAFT || this.checkGeschaeftsjahr();
          }
        }
        //this.initializeChangewatcher(["auftrag"]);
      } else {
        console.error("Auftrag ist null");
      }

      this.editable = !this.berechtigungen.m_finanzen.update
        ? false
        : this.editable;
    },

    checkGeschaeftsjahr() {
      let success = false;
      let geschaeftsjahrDatum = new Date();
      let rechnungsdatumJahr = this.parseDate(
        this.auftrag.rechnungsdatum
      ).getFullYear();

      this.geschaeftsjahre.forEach((geschaeftsjahr) => {
        geschaeftsjahrDatum = this.parseDate(
          geschaeftsjahr.start
        ).getFullYear();
        if (
          geschaeftsjahrDatum <= rechnungsdatumJahr &&
          geschaeftsjahr.geschaeftsjahrstatus.bezeichnung.toLowerCase() ==
          "offen"
        ) {
          success = true;
        }
      });
      return success;
    },

    parseDate(date) {
      var parts = date.match(/(\d+)/g);

      return new Date(parts[2], parts[1] - 1, parts[0]);
    },

    showAlertModal(title, text, color) {
      this.alertModal.title = title;
      this.alertModal.text = text;
      this.alertModal.color = color;
      $("#modal-alert").modal("show");
    },

    async setPositionen(positionen) {
      this.positionen = positionen;
    },
    updateRechnungAn() {
      let adressierung = this.getAdressierung();
      if (
        this.auftrag.rechnung_an_fix &&
        !this.auftrag.strasse_nr &&
        !this.auftrag.plz &&
        !this.auftrag.ort &&
        !this.auftrag.kanton
      ) {
        this.auftrag.rechnung_an = adressierung;
      }

      if (
        this.auftrag.strasse_nr ||
        this.auftrag.plz ||
        this.auftrag.ort ||
        this.auftrag.kanton ||
        this.auftrag.rechnung_an_fix
      ) {
        this.auftrag.rechnung_an = `${adressierung}\n${this.auftrag.strasse_nr}\n${this.auftrag.plz} ${this.auftrag.ort}`;
      }
    },

    rechnungKopierenModal() {
      this.$bus.$emit("open-modal", "rechnung-kopieren-modal");
    },

    kopiereRechnung() {
      var json = Object.assign({}, this.auftrag);
      json.id = "";
      if (this.auftrag.rechnungsadresse != null)
        json.rechnungsadresse = this.auftrag.rechnungsadresse.id
          ? this.auftrag.rechnungsadresse.id
          : this.auftrag.rechnungsadresse;

      if (this.auftrag.person?.id) json.person = this.auftrag.person.id;
      if (this.auftrag.firma?.id) json.firma = this.auftrag.firma.id;

      if (this.auftrag.unserKontakt)
        json.unserKontakt = this.auftrag.unserKontakt.id;
      if (this.auftrag.auftragsstatus)
        json.auftragsstatus = this.auftrag.auftragsstatus.id;
      if (this.positionen) json.positionen = this.positionen;

      json.buchungsstatus = "1751D1D2991";
      json.verbuchtAm = null; // id von "unverbucht"
      if (this.auftrag.quelleAuftrag)
        json.quelleAuftrag = this.auftrag.quelleAuftrag.id;
      if (this.auftrag.zahlungskondition)
        json.zahlungskondition = this.auftrag.zahlungskondition.id;
      if (this.auftrag.zahlungsplan)
        json.zahlungsplan = this.auftrag.zahlungsplan.id;
      if (this.auftrag.konto) json.konto = this.auftrag.konto.id;
      if (this.auftrag.waehrung) json.waehrung = this.auftrag.waehrung.id;
      if (this.auftrag.zahlungsverbindung)
        json.zahlungsverbindung = this.auftrag.zahlungsverbindung.id;

      Api.post("auftrag/", json)
        .then((response) => {
          this.copyAuftragId = response.data.id;
          this.positionen.forEach((position) => {
            position.auftrag = this.copyAuftragId;
            position.artikel = position.artikel.id;
            Api.post("auftrag/position/", position);
          });
          this.$router.push({
            name: "Auftrag",
            params: {id: this.copyAuftragId, anzeige: 0},
          });
          this.initializeAuftrag(response.data);
          this.positionenKey++;
          this.ladeAuftrag();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.ordercopiedsuccessfully"),
          });
        })
        .finally(() => {
          this.$bus.$emit("open-modal", "in-kopierter-rechnung-modal");
        });
    },

    setPerson(person) {
      this.auftrag.person = person;
      this.gpChanged = true;
      if (this.auftrag.firma?.id) {
        this.ladeAdressen(person?.id, this.auftrag.firma?.id, true); //Wenn Firma gesetzt wird und anschliessend die Person gewählt wird, soll die Firmenadresse nicht überschrieben werden
      } else {
        this.ladeAdressen(person?.id, null);
      }
    },

    setFirma(firma) {
      this.auftrag.firma = firma;
      this.gpChanged = true;
      if (this.auftrag.person?.id) {
        this.ladeAdressen(this.auftrag.person?.id, firma?.id);
      } else {
        this.ladeAdressen(null, firma?.id);
      }
    },

    setKontakt(unserKontakt) {
      this.auftrag.unserKontakt = unserKontakt;
    },

    abbrechen() {
      this.$router.push({name: "auftraege"});
    },

    speichern() {
      if (this.loading) return;
      if (
        this.invalid ||
        !this.rechnungsDatumValid ||
        !this.validSelection ||
        (this.auftrag.rechnung_an_fix &&
          (!this.auftrag.strasse_nr ||
            !this.auftrag.plz ||
            !this.auftrag.ort ||
            !this.auftrag.kanton))
      )
        return;

      this.loading = true;
      var json = Object.assign({}, this.auftrag);

      if (this.auftrag.rechnung_an_fix != null) {
        json.strasse_nr = this.auftrag.strasse_nr;
        json.plz = this.auftrag.plz;
        json.ort = this.auftrag.ort;
        json.kanton = this.auftrag.kanton;
      }
      if (this.auftrag.rechnungsadresse != null)
        json.rechnungsadresse = this.auftrag.rechnungsadresse.id
          ? this.auftrag.rechnungsadresse.id
          : this.auftrag.rechnungsadresse;

      if (this.auftrag.person?.id) {
        json.person = this.auftrag.person.id;
      } else {
        json.person = null;
      }

      if (this.auftrag.firma?.id) {
        json.firma = this.auftrag.firma.id;
      } else {
        json.firma = null;
      }

      if (this.auftrag.unserKontakt)
        json.unserKontakt = this.auftrag.unserKontakt.id;
      if (this.auftrag.auftragsstatus)
        json.auftragsstatus = this.auftrag.auftragsstatus.id;
      if (this.auftrag.buchungsstatus)
        json.buchungsstatus = this.auftrag.buchungsstatus.id;
      if (this.auftrag.quelleAuftrag)
        json.quelleAuftrag = this.auftrag.quelleAuftrag.id;
      if (this.auftrag.zahlungskondition)
        json.zahlungskondition = this.auftrag.zahlungskondition.id;
      if (this.auftrag.zahlungsplan)
        json.zahlungsplan = this.auftrag.zahlungsplan.id;
      if (this.auftrag.konto) json.konto = this.auftrag.konto.id;
      if (this.auftrag.waehrung) json.waehrung = this.auftrag.waehrung.id;
      if (this.auftrag.zahlungsverbindung)
        json.zahlungsverbindung = this.auftrag.zahlungsverbindung.id;

      if (!this.auftrag.id) {
        Api.post("auftrag/", json)
          .then((response) => {
            this.initializeAuftrag(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.ordersavedsuccessfully"),
            });

            this.$router.replace({
              name: "auftrag.id.anzeige",
              params: {id: response.data.id, anzeige: 0},
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.ladeAuftrag(this.id);
          });
      } else {
        Api.put("auftrag/", json, {params: {id: this.auftrag.id}})
          .then((response) => {
            this.initializeAuftrag(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.ordersavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.ladeAuftrag(this.id);
          });
      }
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },

    updateZahlungsziel() {
      if (this.auftrag.rechnungsdatum == null) return;
      console.log("update zahlungsziel");
      const parts = this.auftrag.rechnungsdatum.match(/(\d+)/g);
      let rgDatum = new Date(parts[2], parts[1] - 1, parts[0]).addDays(
        this.auftrag.zahlungskondition.tage
      );

      this.auftrag.zahlungsziel = new Intl.DateTimeFormat("de-CH").format(
        rgDatum
      );
    },

    rechnungLoeschen() {
      Api.delete("auftrag/", {
        data: [this.id],
      }).then(() => {
        this.$router.push({name: "auftraege"});
      });
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.invoicedeleted"),
      });
    },

    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },

    loeschen() {
      Api.delete("auftrag/", {
        params: {id: this.id},
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({name: "auftraege"});
      });
    },

    oeffneRechnungsplitModal() {
      this.$bus.$emit("open-modal", "rechnungsplit-modal");
    },

    reloadAuftrag() {
    },
    async searchPersonen(search, loading) {
      let response = await Api.get("geschaeftspartner/", {
        params: {suche: search},
      });
      this.personen = response.data;
      loading(false);
    },

    personLabel(val) {
      if (typeof val === "object") return `${val.nummer} - ${val.personName}`;
      else if (val && this.person) {
        return `${this.person.nummer} - ${this.person.personName}`;
      }

      let person = this.personen.find((p) => p.id === val);
      if (person) return `${person.nummer} - ${person.personName}`;
    },

    unserKontaktLabel(val) {
      if (typeof val === "object") return `${val.nummer} - ${val.personName}`;
      else if (val && this.auftrag.unserKontakt) {
        return `${this.auftrag.unserKontakt.nummer} - ${this.auftrag.unserKontakt.personName}`;
      }

      let person = this.personen.find((p) => p.id === val);
      if (person) return `${person.nummer} - ${person.personName}`;
    },

    async ladeAdressen(gpId, fiId, firmenadresseBeibehalten) {
      this.adressenVerbObjects = [];
      if (gpId || fiId) {
        let response = await Api.get("geschaeftspartner/adressverbindung/", {
          params: {gpid: gpId, fiid: fiId},
        });
        this.adressen = response?.data;

        for (let i = 0; i < this.adressen.length; i++) {
          if (this.adressen[i].adresse)
            this.adressenVerbObjects.push(this.adressen[i].adresse);
        }

        // zum Herausfinden, ob eine der Adressen eine RG adresse ist.
        // Falls ja fülle diese in var "istRechnungsadresse", damit diese als erstes angezeigt wird.
        var hatRechnungsadresse = false;
        var istRechnungsadresse = null;
        var rechnungsadresseSet = false;

        this.adressen.forEach((a) => {
          if (a.adressverwendungen) {
            a.adressverwendungen.forEach((av) => {
              if (av.id === "174D3E55878") {
                hatRechnungsadresse = true; // id von Rechnungsadresse
              }
            });
            if (hatRechnungsadresse && !rechnungsadresseSet) {
              istRechnungsadresse = a;
              rechnungsadresseSet = true; // damit nicht RG-Adresse von späterer Adresse ueberschrieben wird.
            }
          }
        });
        if (this.adressen && !hatRechnungsadresse) {
          // Wenn in keiner Adresse eine Adressverwendung vorkommt oder keine Adresse eine Rechnungsadresse ist, wird die erste Adresse eingefüllt
          this.auftrag.rechnungsadresse = this.adressen[0].adresse;
        }

        if (this.gpChanged) {
          if (this.auftrag.person?.id && !this.auftrag.firma?.id) {
            this.auftrag.empfaenger =
              this.auftrag.person.vorname + " " + this.auftrag.person.name;
          } else if (this.auftrag.firma?.id && !this.auftrag.person?.id) {
            this.auftrag.empfaenger = this.auftrag.firma.name;
          } else if (this.auftrag.firma?.id && this.auftrag.person?.id) {
            this.auftrag.empfaenger =
              this.auftrag.firma.name +
              " / " +
              this.auftrag.person.vorname +
              " " +
              this.auftrag.person.name;
          }

          if (!firmenadresseBeibehalten) {
            this.auftrag.rechnung_an = null;
            this.auftrag.rechnungadresszusatz = null;
            if (istRechnungsadresse && !this.auftrag.rechnung_an_fix) {
              this.auftrag.rechnungsadresse = istRechnungsadresse.adresse;
            }
          } else if (gpId && fiId) {
            let adressierung = "";

            if (
              this.auftrag.person != null &&
              this.auftrag.person.geschlecht != null
            ) {
              adressierung =
                this.auftrag.person.geschlecht.id ==
                this.$CONST("GESCHLECHTER").MALE
                  ? this.$t("global.mr") + " "
                  : this.$t("global.ms") + " ";
            }
            adressierung +=
              this.auftrag.person.vorname + " " + this.auftrag.person.name;

            this.auftrag.rechnung_an =
              this.auftrag.firma.name +
              "\n" +
              adressierung +
              "\n" +
              this.auftrag.rechnungsadresse.adresse_label;
          } else if (!gpId && fiId) {
            if (this.auftrag.adresszusatz) {
              this.auftrag.rechnung_an =
                this.auftrag.adresszusatz +
                "\n" +
                this.auftrag.rechnungsadresse.adresseKomplett.replace(
                  /, /g,
                  "\n"
                );
            } else {
              this.auftrag.rechnung_an =
                this.auftrag.rechnungsadresse.adresseKomplett.replace(
                  /, /g,
                  "\n"
                );
            }
          }

          this.gpChanged = false;
        }
      } else {
        this.auftrag.rechnungsadresse = null;
        this.auftrag.rechnung_an = null;
        this.auftrag.rechnungadresszusatz = null;
      }
    },

    async buchen() {
      let response = await Api.post("auftrag/buchen/", [this.id]);

      if (response.data.success.includes(this.id)) this.ladeAuftrag(this.id);
    },

    // *** PRINTOUTS ***/

    ausgebenRechnungPdfCache(invoiceKey) {
      this.pdfFiles = [];

      let printIds = [this.auftrag.id];

      if (printIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
        return;
      }

      if (printIds > 100) {
        let index = 1,
          counter = parseInt(printIds.length / 100, 10);

        if (parseInt(printIds.length / 100, 10) != printIds.length / 100)
          counter++;

        for (var i = 0; i < printIds.length; i += 100) {
          let subList = printIds.slice(i, i + 100);
          let ids = subList.join(",");

          this.generatePDF(invoiceKey, index, ids, counter, subList.length);
          index++;
        }
      } else {
        let ids = printIds.join(",");
        this.generatePDF(invoiceKey, 1, ids, 1, printIds.length);
      }

      this.$refs.pdfLayer.show();
    },

    generatePDF(invoiceKey, index, ids, counter, size) {
      let json = {
        idlist: ids,
      };

      Api.post("printcache/", json, {params: {}}).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=RG" +
          filename +
          ".pdf&report=/api" +
          this.$CONST("PRINTOUTS")[invoiceKey] +
          "&cache=" +
          cacheId;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            size: size,
            url:
              (server.url && server.url != "" ? server.url : "") +
              response.data.filepath,
            withCredentials: true,
            count: counter,
          };

          this.pdfFiles.push(retValue);
        });
      });
    },
    isValidCarbonePrintout(printout) {
      let isValid = false;
      if (printout.appkey.includes("offerte" || "rechnung" || "mahnung")) {
        isValid = true;
      }
      return isValid;
    },
    ausgebenKopiekostenPdfCache(invoiceKey) {
      this.pdfFiles = [];

      let printIds = [this.auftrag.id];

      if (printIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
        return;
      }

      if (printIds > 100) {
        let index = 1,
          counter = parseInt(printIds.length / 100, 10);

        if (parseInt(printIds.length / 100, 10) != printIds.length / 100)
          counter++;

        for (var i = 0; i < printIds.length; i += 100) {
          let subList = printIds.slice(i, i + 100);
          let ids = subList.join(",");

          this.generateKopiekostenPDF(
            invoiceKey,
            index,
            ids,
            counter,
            subList.length
          );
          index++;
        }
      } else {
        let ids = printIds.join(",");
        this.generateKopiekostenPDF(invoiceKey, 1, ids, 1, printIds.length);
      }

      this.$refs.pdfLayer.show();
    },
    generateKopiekostenPDF(invoiceKey, index, ids, counter, size) {
      let json = {
        idlist: ids,
      };

      Api.post("printcache/", json, {params: {}}).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=Kopiekosten_" +
          filename +
          ".pdf&report=/api" +
          this.$CONST("PRINTOUTS")[invoiceKey] +
          "&cache=" +
          cacheId +
          "&date=" +
          filename;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            size: size,
            url:
              (server.url && server.url != "" ? server.url : "") +
              response.data.filepath,
            withCredentials: true,
            count: counter,
          };

          this.pdfFiles.push(retValue);
        });
      });
    },
    mailMitOfferteErstellen() {
    },

    /*  ausgebenRechnung(invoiceKey) {
      if (this.auftrag.id) {
        let filename = this.auftrag.id;

        this.pdfPath =
          server.url +
          "/pdfgenerator/pdf.act?filename=RG" +
          filename +
          ".pdf&report=" +
          this.$CONST("PRINTOUTS")[invoiceKey] +
          "&ids=" +
          this.auftrag.id;
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
      }
    }, */
    /*  rechnungsUebersichtAusgeben() {
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=Kopiekosten_${filename}.pdf&report=/pdfgenerator/kopiekosten.htm&auftragID=${this.auftrag.id}&ids=${this.personId}&date=${filename}`;

      this.$refs.pdfLayer.show();
    }, */
  },
};
</script>

<style></style>
