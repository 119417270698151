<template>
  <div id="publikationszeitraum-setzen-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.setpublicationperiod") }}</h4>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            {{ $t("global.infotextsetpublicationperiod") }}
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="dokument.publikationsStart"
                  @update="(val) => (dokument.publikationsStart = val)"
                  :show-icon="true"
                  :inModal="true"
                />
                <label>{{ $t("global.publicationfrom") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="dokument.publikationsEnde"
                  @update="(val) => (dokument.publikationsEnde = val)"
                  :show-icon="true"
                  :inModal="true"
                />
                <label>{{ $t("global.publicationuntil") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-danger" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button
            class="btn btn-success"
            @click="publikationszeitraumSpeichern"
          >
            {{ $t("global.setpublicationperiod") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppApi from "@/AppApi";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import DatePicker from "@/components/Datepicker";

export default {
  name: "PublikationszeitraumSetzenModal",
  components: {
    ModalCloseButton,
    DatePicker,
  },
  metaInfo() {},
  mixins: [],
  props: {
    dokumentenIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dokument: {},
      shown: false,
    };
  },
  mounted: function () {
    this.$bus.$on("openPublikationszeitraumSetzenModal", () => {
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    publikationszeitraumSpeichern() {
      // loading overlay start
      let json = {
        dokumentIds: this.dokumentenIds,
        publikationsStart: this.dokument.publikationsStart,
        publikationsEnde: this.dokument.publikationsEnde,
      };

      // PUT auf endpoint dokument/publikationszeitraum
      AppApi.put("dokument/publikationszeitraum/", json)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.publicationperiodset"),
            });
            this.$emit("updated");
          } else {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.publicationperiodsetfailed"),
            });
          }
        })
        .catch((e) => {
          this.$notify({
            type: "warn",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.publicationperiodsetfailed"),
          });
        })
        .finally(() => {
          //finally loading overlay end & closemodal
          this.closeModal();
        });
    },

    openModal() {
      $("#publikationszeitraum-setzen-modal").modal({
        backdrop: "static",
        keyboard: false,
      });

      this.shown = false;
    },

    closeModal() {
      $("#publikationszeitraum-setzen-modal").modal("hide");
    },
  },
};
</script>

<style></style>
