<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie px-3">
          <div class="body">
            <div v-if="gpId == null" class="row">
              <div class="mut-header-bg ml-0">
                <div
                  class="mut-header pointer"
                  style="height: 50px"
                  :class="{ 'mut-selected': activeHeadline === 0 }"
                  @click="setNachTeilnehmer"
                >
                  <div class="mut-headers">
                    <span class="primary-headline-text">{{
                      "nach Teilnehmer"
                    }}</span>
                  </div>
                </div>
                <div
                  class="mut-header pointer"
                  style="height: 50px"
                  :class="{ 'mut-selected': activeHeadline === 1 }"
                  @click="setNachLehrgang"
                >
                  <div class="mut-headers">
                    <span class="primary-headline-text">{{
                      "nach Lehrgang"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="ml-1 mr-2">
                <button
                  class="btn btn-primary ml-2"
                  @click="openPublicationModal"
                  :disabled="!selectedIds || !editable"
                >
                  <font-awesome-icon icon="fa-regular fa-upload" class="mr-2" />
                  {{ $t("global.setpublicationperiod") }}
                </button>
              </div>
              <div class="mr-2" style="max-width: 10%">
                <div class="form-group pt-0">
                  <input
                    v-model="filters['bezeichnung']"
                    class="form-control input"
                    type="text"
                    :placeholder="$t('global.designation')"
                  />
                </div>
              </div>
              <div class="mr-2" style="min-width: 9%">
                <div class="form-group pt-0">
                  <v-select
                    v-model.trim="filters.kategorie"
                    :options="dokumentKategorien"
                    :reduce="(r) => r.id"
                    label="bezeichnung"
                    :placeholder="$t('global.categories')"
                    style="z-index: 15"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                </div>
              </div>
              <div class="mr-2">
                <div
                  class="form-group pt-0"
                  v-tooltip.hover="$t('global.publishedfrom')"
                >
                  <date-picker
                    date-format="dd.mm.yy"
                    :placeholder="$t('global.publishedfrom')"
                    :initial="filters['publiziertSeit']"
                    :show-icon="true"
                    field="publiziertSeit"
                    @updatefull="setFilterDate"
                  />
                </div>
              </div>
              <div class="mr-2">
                <div
                  class="form-group pt-0"
                  v-tooltip.hover="$t('global.publisheduntil')"
                >
                  <date-picker
                    date-format="dd.mm.yy"
                    :placeholder="$t('global.publisheduntil')"
                    :initial="filters['publiziertBis']"
                    :show-icon="true"
                    field="publiziertBis"
                    @updatefull="setFilterDate"
                  />
                </div>
              </div>
              <div class="mr-2">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="getDocuments()"
                  v-tooltip.hover="$t('global.search')"
                >
                  <font-awesome-icon icon="fa-regular fa-magnifying-glass" />
                </b-button>
              </div>
              <div class="mr-2">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="clearFilter()"
                  v-tooltip.hover="$t('global.reset')"
                >
                  <font-awesome-icon icon="fa-light fa-xmark" />
                </b-button>
              </div>
              <div class="float-right mr-2">
                <b-button
                  class="ml-2"
                  variant="outline-primary"
                  @click="switchSetAll()"
                >
                  <span>
                    {{
                      setAll ? $t("global.deselectall") : $t("global.selectall")
                    }}
                  </span>
                </b-button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 block belegliste br-t-l-0">
                <b-table
                  hover
                  responsive
                  sticky-header
                  show-empty
                  :items="datalist"
                  :fields="activeHeadline === 0 ? fieldsT : fieldsV"
                  :busy="isBusy"
                  tbody-tr-class="item"
                  ref="selectableTable"
                  style="max-height: 430px"
                >
                  <template v-slot:cell(publikationszeitraum)="parentRow">
                    <div class="text-center">
                      {{
                        parentRow.item.publiziert_seit !== "-"
                          ? parentRow.item.publiziert_seit
                          : $t("global.open")
                      }}
                      -
                      {{
                        parentRow.item.publiziert_bis !== "-"
                          ? parentRow.item.publiziert_bis
                          : $t("global.open")
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(publizieren)="parentRow">
                    <b-form-checkbox
                      v-model="parentRow.item.publizieren"
                      @change="setChildRows(parentRow.item)"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(details)="parentRow">
                    <b-button
                      @click="parentRow.toggleDetails"
                      class="m-0"
                      variant="outline-primary"
                      size="sm"
                    >
                      <font-awesome-icon
                        :icon="
                          parentRow.detailsShowing
                            ? 'fa-chevron-up'
                            : 'fa-chevron-down'
                        "
                      />
                    </b-button>
                  </template>
                  <template #row-details="parentRow">
                    <div class="my-1 px-2">
                      <b-table
                        thead-class="z"
                        tbody-tr-class="item"
                        small
                        responsive
                        sticky-header
                        :items="parentRow.item.dokumente"
                        :fields="
                          activeHeadline === 0 ? detailFieldsT : detailFieldsV
                        "
                        :busy="isBusy"
                        show-empty
                        sort-icon-left
                        selectable
                        select-mode="range"
                        thead-tr-class="no-bg"
                        ref="selectableTable2"
                      >
                        <template v-slot:cell(publiziert)="row">
                          <div class="text-center">
                            <font-awesome-icon
                              :icon="
                                row.item.publiziert ? 'fa-check' : 'fa-xmark'
                              "
                            />
                          </div>
                        </template>
                        <template v-slot:cell(publikationszeitraum)="row">
                          <div class="text-center">
                            {{
                              row.item.publiziert_seit !== "-"
                                ? row.item.publiziert_seit
                                : $t("global.open")
                            }}
                            -
                            {{
                              row.item.publiziert_bis !== "-"
                                ? row.item.publiziert_bis
                                : $t("global.open")
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(publizieren)="row">
                          <b-form-checkbox
                            v-model="row.item.publizieren"
                            @change="onRowSelected(row, parentRow)"
                          ></b-form-checkbox>
                        </template>
                        <template #table-busy>
                          <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                          </div>
                        </template>

                        <template #empty>
                          <div
                            v-if="ladeFehler"
                            class="text-center text-danger my-2"
                          >
                            <strong>{{
                              $t("global.errorwhileloading")
                            }}</strong>
                          </div>
                          <div
                            v-if="!ladeFehler"
                            class="text-center text-primary my-2"
                          >
                            <strong>{{ $t("global.nodataavailable") }}</strong>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </template>
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>
                  <template #empty>
                    <div v-if="ladeFehler" class="text-center text-danger my-2">
                      <strong>{{ $t("global.errorwhileloading") }}</strong>
                    </div>
                    <div
                      v-if="!ladeFehler"
                      class="text-center text-primary my-2"
                    >
                      <strong>{{ $t("global.nodataavailable") }}</strong>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <publikationszeitraum-setzen-modal
      :dokumentenIds="selectedIds"
      @updated="publikationszeitraumSetzenGeaendert"
    />
  </div>
</template>

<script>
import filterNew from "@/mixins/FilterNew";
import DatePicker from "@/components/Datepicker.vue";
import { LADE_AUSWAHLWERTE_ANMELDUNG } from "@/store/anmeldung/actions.type";
import AppApi from "../../AppApi";
import { LADE_AUSWAHLWERTE_DOKUMENT } from "@/store/dokument/actions.type";
import PublikationszeitraumSetzenModal from "@/components/Modals/PublikationszeitraumSetzenModal.vue";

export default {
  name: "Dokumentenuebersicht",
  components: { PublikationszeitraumSetzenModal, DatePicker },
  mixins: [filterNew],
  props: {
    id: {
      type: String,
      required: true,
    },
    lehrgangId: {
      type: String,
      required: false,
      default: null,
    },
    gpId: {
      type: String,
      required: false,
      default: null,
    },
    veranstaltungsId: {
      type: String,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
    },
  },
  data() {
    return {
      configurationAvailable: false,
      selectedIds: [],
      setAll: false,
      isBusy: false,
      ladeFehler: false,
      shown: false,
      activeHeadline: 0,
      datalist: [],
      dokumente: {
        personenDokumenteJson: [],
        veranstaltungDokumenteJson: [],
      },
    };
  },
  computed: {
    fieldsT() {
      return [
        {
          key: "person_name",
          label: this.$t("global.participants"),
        },
        {
          key: "anmeldestatus_id",
          label: this.$t("global.statusregistration"),
          formatter: (value) => {
            return value !== "-"
              ? this.anmeldestati.find(
                  (anmeldestatus) => anmeldestatus.id === value
                )?.bezeichnung
              : "-";
          },
        },
        {
          key: "anmeldetyp_id",
          label: this.$t("global.registrationtype"),
          formatter: (value) => {
            return value !== "-"
              ? this.anmeldetypen.find((anmeldetyp) => anmeldetyp.id === value)
                  ?.bezeichnung
              : "-";
          },
        },
        {
          key: "publizieren",
          label: this.$t("global.Publish"),
          thStyle: "width: 10%;",
          class: "text-center",
        },
        {
          key: "details",
          label: "",
          thStyle: "width: 5%;",
        },
      ];
    },
    fieldsV() {
      return [
        {
          key: "bezeichnung",
          label: this.$t("global.document"),
        },
        {
          key: "erstelldatum",
          label: this.$t("global.creationdate"),
        },
        {
          key: "dokument_kategorie_id",
          label: this.$t("global.documenttype"),
          formatter: (value) => {
            return value !== "-"
              ? this.dokumentKategorien.find(
                  (dokumentKategorie) => dokumentKategorie.id === value
                )?.bezeichnung
              : value;
          },
        },
        {
          key: "publizieren",
          label: this.$t("global.Publish"),
          thStyle: "width: 10%;",
          class: "text-center",
        },
        {
          key: "details",
          label: "",
          thStyle: "width: 5%;",
        },
      ];
    },
    detailFieldsT() {
      return [
        {
          key: "bezeichnung",
          label: this.$t("global.designation"),
        },
        {
          key: "erstelldatum",
          label: this.$t("global.creationdate"),
        },
        {
          key: "dokument_kategorie_id",
          label: this.$t("global.documenttype"),
          formatter: (value) => {
            return value !== "-"
              ? this.dokumentKategorien.find(
                  (dokumentKategorie) => dokumentKategorie.id === value
                )?.bezeichnung
              : value;
          },
        },
        {
          key: "publiziert",
          label: this.$t("global.published"),
          class: "text-center",
        },
        {
          key: "publikationszeitraum",
          label: this.$t("global.timeperiod"),
        },
        {
          key: "publizieren",
          label: this.$t("global.Publish"),
          thStyle: "width: 10%;",
          class: "text-center",
        },
      ];
    },
    detailFieldsV() {
      return [
        {
          key: "person_name",
          label: this.$t("global.participant"),
        },
        {
          key: "anmeldestatus_id",
          label: this.$t("global.statusregistration"),
          formatter: (value) => {
            return value !== "-"
              ? this.anmeldestati.find(
                  (anmeldestatus) => anmeldestatus.id === value
                )?.bezeichnung
              : value;
          },
        },
        {
          key: "anmeldetyp_id",
          label: this.$t("global.registrationtype"),
          formatter: (value) => {
            return value !== "-"
              ? this.anmeldetypen.find((anmeldetyp) => anmeldetyp.id === value)
                  ?.bezeichnung
              : value;
          },
        },
        {
          key: "bezeichnung",
          label: this.$t("global.designation"),
        },
        {
          key: "publiziert",
          label: this.$t("global.published"),
          class: "text-center",
        },
        {
          key: "publikationszeitraum",
          label: this.$t("global.timeperiod"),
        },
        {
          key: "publizieren",
          label: this.$t("global.Publish"),
          thStyle: "width: 10%;",
          class: "text-center",
        },
      ];
    },
    anmeldestati: {
      get() {
        return this.$store.state.anmeldung.anmeldestati;
      },
    },
    anmeldetypen: {
      get() {
        return this.$store.state.anmeldung.anmeldetypen;
      },
    },
    dokumentKategorien: {
      get() {
        return this.$store.state.dokument.dokumentkategorie;
      },
    },
    queryParams: function () {
      var params = {};

      params.bezeichnung = this.setQueryParam(this.filters["bezeichnung"]);
      params.kategorie = this.setQueryParam(this.filters.kategorie);
      params.publiziertSeit = this.filters["publiziertSeit"];
      params.publiziertBis = this.filters["publiziertBis"];

      return params;
    },
  },
  watch: {},
  created() {
    if (this.anmeldetypen.length == 0)
      this.$store.dispatch(`anmeldung/${LADE_AUSWAHLWERTE_ANMELDUNG}`);
    if (this.dokumentKategorien.length == 0)
      this.$store.dispatch(`dokument/${LADE_AUSWAHLWERTE_DOKUMENT}`);
  },
  mounted() {
    this.getDocuments();
  },

  methods: {
    getDocuments() {
      if (this.gpId != null && this.gpId !== "") {
        AppApi.get("dokument/reiter/person/", {
          params: {
            id: this.gpId,
            ...this.queryParams,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              this.dokumente.personenDokumenteJson =
                response.data.personen_dokumente_json;
              this.setDatalist();
            }
          })
          .catch(() => {
            this.ladeFehler = true;
          });
      }

      if (this.veranstaltungsId != null && this.veranstaltungsId !== "") {
        AppApi.get("dokument/reiter/veranstaltung/", {
          params: {
            id: this.veranstaltungsId,
            ...this.queryParams,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              this.dokumente.personenDokumenteJson =
                response.data.personen_dokumente_json;
              this.dokumente.veranstaltungDokumenteJson =
                response.data.veranstaltung_dokumente_json;
              this.setDatalist();
            }
          })
          .catch(() => {
            this.ladeFehler = true;
          });
      }

      if (this.lehrgangId != null && this.lehrgangId !== "") {
        AppApi.get("dokument/reiter/lehrgang/", {
          params: {
            id: this.lehrgangId,
            ...this.queryParams,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              this.dokumente.personenDokumenteJson =
                response.data.personen_dokumente_json;
              this.dokumente.veranstaltungDokumenteJson =
                response.data.veranstaltung_dokumente_json;
              this.setDatalist();
            }
          })
          .catch(() => {
            this.ladeFehler = true;
          });
      }
    },

    setDatalist() {
      let data = this.datalist;
      if (
        this.dokumente.personenDokumenteJson.length !== 0 &&
        this.dokumente.veranstaltungDokumenteJson.length !== 0
      ) {
        this.activeHeadline === 1
          ? (this.datalist = this.dokumente.veranstaltungDokumenteJson)
          : (this.datalist = this.dokumente.personenDokumenteJson);
      } else if (this.dokumente.personenDokumenteJson.length !== 0) {
        this.datalist = this.dokumente.veranstaltungDokumenteJson;
      } else {
        this.datalist = this.dokumente.personenDokumenteJson;
      }
    },

    openPublicationModal() {
      this.$bus.$emit("openPublikationszeitraumSetzenModal");
    },

    publikationszeitraumSetzenGeaendert() {
      this.getDocuments();
    },

    setChildRows(value) {
      value.dokumente.forEach((dokument) => {
        this.$set(dokument, "publizieren", value.publizieren);

        if (
          dokument.publizieren &&
          !this.selectedIds.find((id) => id === dokument.id)
        ) {
          this.selectedIds.push(dokument.id);
        } else if (!dokument.publizieren) {
          this.selectedIds = this.selectedIds.filter(
            (id) => id !== dokument.id
          );
        }
      });
    },

    onRowSelected(row, parentRow) {
      if (row.item.publizieren) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
      this.$set(
        parentRow.item,
        "publizieren",
        parentRow.item.dokumente.some(
          (dokument) => dokument.publizieren === true
        )
      );
    },

    switchSetAll() {
      this.setAll ? (this.setAll = false) : (this.setAll = true);

      if (this.setAll) {
        this.datalist.forEach((dokument) => {
          this.$set(dokument, "publizieren", true);
          this.setChildRows(dokument);
        });
      } else {
        this.datalist.forEach((dokument) => {
          this.$set(dokument, "publizieren", false);
          this.setChildRows(dokument);
        });
      }
    },

    setNachTeilnehmer() {
      this.activeHeadline = 0;
      this.setDatalist();
    },
    setNachLehrgang() {
      this.activeHeadline = 1;
      this.setDatalist();
    },
    clearFilter() {
      this.filters = [];
      this.getDocuments();
    },
  },
};
</script>
<style></style>
