<template>
  <ValidationObserver v-slot="{ invalid }" :disabled="true">
    <div id="lohnansatz-bearbeiten-modal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">
              {{
                lohnansatz.id
                  ? $t("global.editwagerate")
                  : $t("global.addwagerate")
              }}
            </h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="lohnansatz.bezeichnung"
                      type="text"
                      class="form-control"
                      v-bind:class="{ 'border-danger': !passed }"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.designation")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <textarea
                    v-model.trim="lohnansatz.bemerkung"
                    class="form-control"
                    placeholder=" "
                    :tabindex="113"
                  ></textarea>
                  <label>{{ $t("global.comment") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <input
                    type="checkbox"
                    class="mr-2"
                    v-model="lohnansatz.standard"
                  />
                  {{ $t("global.default") }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="lohnansatz.lohnart"
                    label="bezeichnung"
                    :options="lohnarten"
                    :clearable="false"
                  ></v-select>
                  <label>{{ $t("global.wagetype") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <input
                    v-model.number="lohnansatz.ansatz"
                    type="number"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.rate") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="lohnansatz.ertragskostenstelle"
                    :options="kostenstellen"
                    label="bezeichnung"
                    :clearable="false"
                  ></v-select>
                  <label>{{ $t("global.incomecostcenter") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="lohnansatz.aufwand_kostentraeger"
                    :options="kostenstellen"
                    label="bezeichnung"
                    :clearable="false"
                  ></v-select>
                  <label>{{ $t("global.expensecostunit") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="lohnansatz.ertragskonto"
                    :options="konten"
                    :get-option-label="
                      (k) =>
                        k && k.bezeichnung && k.kontonummer
                          ? k.kontonummer +
                            ' / ' +
                            k.bezeichnung +
                            (k.institution ? ' / ' + k.institution : '')
                          : ''
                    "
                    :clearable="false"
                  ></v-select>
                  <label>{{ $t("global.accountincome") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="lohnansatz.aufwandskonto"
                    :options="konten"
                    :get-option-label="
                      (k) =>
                        k && k.bezeichnung && k.kontonummer
                          ? k.kontonummer +
                            ' / ' +
                            k.bezeichnung +
                            (k.institution ? ' / ' + k.institution : '')
                          : ''
                    "
                    :clearable="false"
                  ></v-select>
                  <label>{{ $t("global.accountexpense") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="invalid"
              class="btn btn-primary"
              @click="uebernehmen"
            >
              {{ $t("global.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import Api from "@/Api";
import { LADE_AUSWAHLWERTE_LOHN } from "@/store/lohn/actions.type";
import { LADE_KATEGORIEN } from "@/store/rechnung/actions.type";

export default {
  name: "LohnansatzModal",
  components: { ModalCloseButton },
  props: {
    personID: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      lohnansatz: {},
      editLohnansatz: {},
      shown: false,
    };
  },
  computed: {
    lohnarten() {
      return this.$store.state.lohn.lohnarten;
    },
    kostenstellen() {
      return this.$store.state.rechnung.kostenstellen;
    },
    konten() {
      return this.$store.state.rechnung.konten;
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("open-lohnansatz-modal", (lohnansatzID) => {
      if (!this.shown) {
        this.openModal(lohnansatzID);
      }
    });
  },
  methods: {
    openModal(id) {
      this.shown = true;

      if (this.lohnarten.length == 0)
        this.$store.dispatch(`lohn/${LADE_AUSWAHLWERTE_LOHN}`);

      if (this.kostenstellen.length == 0)
        this.$store.dispatch(`rechnung/${LADE_KATEGORIEN}`);

      if (id) {
        Api.get("lohn/lohnansatz/", {
          params: {
            id: id,
          },
        }).then((response) => {
          this.lohnansatz = response.data;
          $("#lohnansatz-bearbeiten-modal").modal({
            backdrop: "static",
            keyboard: false,
          });
        });
      } else {
        this.lohnansatz = { person: this.personID };
        $("#lohnansatz-bearbeiten-modal").modal({
          backdrop: "static",
          keyboard: false,
        });
      }
    },

    closeModal() {
      this.shown = false;
      this.$emit("abbrechen");
      $("#lohnansatz-bearbeiten-modal").modal("hide");
    },

    uebernehmen() {
      var json = Object.assign({}, this.lohnansatz);

      if (this.lohnansatz.id) {
        Api.put("lohn/lohnansatz/", json, {
          params: { id: this.lohnansatz.id },
        }).then((response) => {
          this.$emit("lohnansatz-changed", response.data);
          this.closeModal();
        });
      } else {
        Api.post("lohn/lohnansatz/", json).then((response) => {
          this.$emit("lohnansatz-created", response.data);
          this.closeModal();
        });
      }
    },
  },
};
</script>

<style></style>
