<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.name"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.name") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.vorname"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.firstname") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['ersteller.personName']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.creator") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-erstellt-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.erstelldatumVon"
                        field="erstelldatumVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.creationdatefrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-erstellt-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.erstelldatumBis"
                        field="erstelldatumBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.creationdateuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.documentslist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <button
                      class="btn btn-primary"
                      @click="oeffnePublikationszeitraumSetzenModal"
                      v-if="berechtigungen.m_dokumente.update"
                      :disabled="selectedIds.length === 0"
                      :title="
                        selectedIds.length === 0
                          ? $t('global.selectminone')
                          : ''
                      "
                      
                    >
                      <font-awesome-icon
                        icon="fad fa-calendar-days"
                        class="mr-2"
                      />{{ $t("global.setpublicationperiod") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_korrespondenz.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste dokumentenliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:cell(geschaeftspartner)="data">
                      <span
                        v-if="data.item.person"
                        @click="
                          routeTo(data.item.person.id, 'geschaeftspartner')
                        "
                      >
                        {{ data.item.person.personName }}
                        <font-awesome-icon icon="fad fa-circle-arrow-right" />
                      </span>

                      {{
                        (data.item.person && data.item.firma ? ", " : "") +
                        (!data.item.person && !data.item.firma ? "-" : "")
                      }}

                      <span
                        v-if="data.item.firma"
                        @click="routeTo(data.item.firma.id, 'firma')"
                      >
                        {{ data.item.firma.name }}
                        <font-awesome-icon icon="fad fa-circle-arrow-right" />
                      </span>
                    </template>

                    <template v-slot:cell(veranstaltung)="data">
                      <span
                        v-if="data.item.veranstaltung"
                        @click="
                          routeTo(
                            data.item.veranstaltung.id,
                            data.item.veranstaltung.typ
                          )
                        "
                      >
                        {{ data.item.veranstaltung.bezeichnung }}
                        <font-awesome-icon icon="fad fa-circle-arrow-right" />
                      </span>
                      <span
                        v-if="data.item.bildungsgang"
                        @click="
                          routeTo(data.item.bildungsgang.id, 'bildungsgang')
                        "
                      >
                        {{ data.item.bildungsgang.bezeichnung }}
                        <font-awesome-icon icon="fad fa-circle-arrow-right" />
                      </span>

                      {{
                        !data.item.veranstaltung && !data.item.bildungsgang
                          ? "-"
                          : ""
                      }}
                    </template>

                    <template v-slot:cell(bezeichnung)="data">
                      <div @click="oeffnePDF(data.item)">
                        {{ data.item.bezeichnung }}
                      </div>
                    </template>

                    <template v-slot:cell(publiziert)="data">
                      <font-awesome-icon
                        v-if="
                          data.item.publiziert ===
                          true
                        "
                        icon="fas fa-eye"
                        style="color: #00610099"
                      />
                      <font-awesome-icon
                        v-if="
                          data.item.publiziert === false
                        "
                        icon="fas fa-eye-slash"
                        style="color: #e0000096"
                      />
                      {{ data.item.publiziert === false ? "nicht publiziert" : "publiziert" }}
                    </template>

                    <template v-slot:cell(publikationszeitraum)="data">
                      {{
                        getPublikationszeitraum(
                          data.item.publiziertSeit,
                          data.item.publiziertBis
                        )
                      }}
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="(state) => infiniteHandler(state, 'app-api')"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <publikationszeitraum-setzen-modal
      :dokumentenIds="selectedIds"
      @updated="publikationszeitraumSetzenGeaendert"
    />

    <loeschen-modal
      id="dokumente-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />

    <messagebox-count
      :headerText="$t('global.documentsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />
    <loading-overlay v-show="loading"></loading-overlay>

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import server from "@/server";

import PublikationszeitraumSetzenModal from "@/components/Modals/PublikationszeitraumSetzenModal.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import DatePicker from "@/components/Datepicker";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import Geschaeftspartner from "../../routes/Geschaeftspartner";

export default {
  name: "Dokumentenliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    DatePicker,
    PublikationszeitraumSetzenModal,
    LoeschenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.documents");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      pdfPath: "",
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "geschaeftspartner",
          searchable: false,
          sortable: true,
          label: this.$t("global.businesspartner"),
        },
        {
          key: "veranstaltung",
          searchable: false,
          sortable: true,
          label: this.$t("global.event"),
        },
        {
          key: "dokumentkategorie",
          searchable: false,
          sortable: true,
          label: this.$t("global.category"),
          formatter: (value) => {
            if (value) {
              return value.bezeichnung;
            } else {
              return "-";
            }
          },
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.documentname"),
        },
        {
          key: "publiziert",
          searchable: false,
          sortable: true,
          label: this.$t("global.publicationstatus"),
        },
        {
          key: "publikationszeitraum",
          searchable: false,
          sortable: true,
          label: this.$t("global.publicationperiod"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.filterName = this.setQueryParam(this.filters.name);
      params.filterVorname = this.setQueryParam(this.filters.vorname);
      params.filterBezeichnung = this.setQueryParam(this.filters.bezeichnung);
      params.filterErsteller = this.setQueryParam(
        this.filters["ersteller.personName"]
      );
      params.filterCount = this.setQueryParam(this.filters.count);

      if (this.filters.erstelldatumVon && this.filters.erstelldatumVon != "..")
        params.filterErstelldatumVon = this.filters.erstelldatumVon;

      if (this.filters.erstelldatumBis && this.filters.erstelldatumBis != "..")
        params.filterErstelldatumBis = this.filters.erstelldatumBis;

      return params;
    },
  },
  created() {
    this.initFilter("dokumente-liste", "dokument/dashboard-liste/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    /*this.listData = [
      {
        id: "21314f3z14t1",
        bezeichnung: "Testbezeichnung",
        publikationsstatus: {
          bezeichnung: "publiziert",
        },
        publikationsstartdatum: "2020-01-01",
        publikationsenddatum: "2020-01-02",
        dokumentkategorie: {
          bezeichnung: "Testkategorie",
        },
        geschaeftspartner: {
          id: "21314f3z14t1",
          personName: "Testperson",
          name: "Testname",
          vorname: "Testvorname",
        },
        firma: {
          id: "21314f3z14t1",
          name: "Testfirma",
        },
        veranstaltung: {
          id: "21314f3z14t1",
          bezeichnung: "Testveranstaltung",
        },
      },
    ];*/
  },
  methods: {
    details(dokumente) {
      this.$router.push({
        path: "/ressourcen/dokument/" + dokumente.id,
      });
    },

    getPublikationszeitraum(von, bis) {
      if (von && bis) {
        return von + " - " + bis;
      } else if (von) {
        return von
      } else {
        return "-";
      }
    },

    loeschen() {
      this.delete("dokument/", this.selectedIds);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "dokumente-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noitemselected"),
        });
      }
    },

    routeTo(id, routeName) {
      const route = this.$router.resolve({
        name: routeName,
        params: { id: id, anzeige: 0 },
      });

      window.open(route.href, "_blank");
    },

    oeffnePDF(item) {
      this.pdfPath = server.url + item.dokument;
      this.$refs.pdfLayer.show();
    },

    publikationszeitraumSetzenGeaendert() {
      this.resetLoading(this);
    },

    oeffnePublikationszeitraumSetzenModal() {
      this.$bus.$emit("openPublikationszeitraumSetzenModal");
    },
  },
};
</script>

<style></style>
