import {
  SET_DOKUMENTKATEGORIE
} from "./mutations.type";

import {LADE_AUSWAHLWERTE_DOKUMENT} from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    dokumentkategorie	: []
  },
  mutations: {
    [SET_DOKUMENTKATEGORIE](state, val) {
      state.dokumentkategorie = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_DOKUMENT](context) {
      let response = await Api.get("dokument/auswahlwerte/");
      context.commit(SET_DOKUMENTKATEGORIE, response.data.dokumentkategorie	);
    },
  }
};
