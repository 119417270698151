<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.auftragsnummer"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.ordernum") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.buchungstatus"
                        :options="buchungsstati"
                        :reduce="(b) => b.id"
                        label="bezeichnung"
                        :placeholder="$t('global.bookingstatus')"
                        multiple=""
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.bookingstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.auftragstatus"
                        :options="auftragsstati"
                        :reduce="(b) => b.id"
                        label="bezeichnung"
                        :placeholder="$t('global.orderstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.orderstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.zahlungstatus"
                        :options="zahlungsstati"
                        :reduce="(b) => b.id"
                        label="bezeichnung"
                        :placeholder="$t('global.paymentstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.paymentstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.artikel"
                        label="bezeichnung"
                        :options="artikelListe"
                        :get-option-label="
                          (e) =>
                            `${e.artikelnummer} / ${e.kurzbezeichnung} / ${e.bezeichnung}`
                        "
                        :placeholder="$t('global.items')"
                        multiple
                      >
                      </v-select>
                      <label>{{ $t("global.item") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-von"
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.rechnungsdatum_von"
                        field="rechnungsdatum_von"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.invoicedatefrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-bis"
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.rechnungsdatum_bis"
                        field="rechnungsdatum_bis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.invoicedateuntil") }}</label>
                    </div>
                  </div>
                </div>
                <!--
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.rechnungsdatum"
                        field="rechnungsdatum"
                        @updatefull="setFilterDate"
                      />
                      <label>Rechnungsdatum ab</label>
                    </div>
                  </div>
                </div>
                -->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.totalBrutto"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.grossamount") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.totalBruttoVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.grossamountfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.totalBruttoBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.grossamountupto") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['person.personName']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.person") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.firma"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.company") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.orderlist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <button
                      class="btn btn-success mr-2"
                      @click="neuerAuftrag"
                      v-if="berechtigungen.m_finanzen.create"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />
                      {{ $t("global.new") }}
                    </button>

                    <b-dropdown variant="primary mr-2">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-print"
                          class="mr-2"
                        />
                        {{ $t("global.selected") }}
                      </template>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE1', true)"
                        v-if="$CONST('PRINTOUTS').INVOICE1 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE1_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE2', true)"
                        v-if="$CONST('PRINTOUTS').INVOICE2 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE2_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE3', true)"
                        v-if="$CONST('PRINTOUTS').INVOICE3 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE3_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE4', true)"
                        v-if="$CONST('PRINTOUTS').INVOICE4 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE4_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE5', true)"
                        v-if="$CONST('PRINTOUTS').INVOICE5 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE5_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE6', true)"
                        v-if="$CONST('PRINTOUTS').INVOICE6 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE6_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE7', true)"
                        v-if="$CONST('PRINTOUTS').INVOICE7 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE7_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="carboneprint in validCarbonePrintouts(
                          $CONST('CARBONEPRINTOUTS')
                        )"
                        :key="carboneprint.id"
                        @click="
                          carbone(
                            carboneprint.category.bezeichnung,
                            carboneprint.template,
                            'carbone-finanzen/?ids='
                          )
                        "
                      >
                        {{ carboneprint.value }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenKopiekostenPdfCache('INVOICE8', true)"
                        v-if="
                          (baseURL =
                            'https://tsp.bx-education.ch' &&
                            $CONST('PRINTOUTS').INVOICE8 != null)
                        "
                      >
                        <span>{{ $CONST("PRINTOUTS").INVOICE8_LABEL }}</span>
                      </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown variant="primary mr-2">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-print"
                          class="mr-2"
                        />
                        {{ $t("global.alldisplayed") }}
                      </template>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE1', false)"
                        v-if="$CONST('PRINTOUTS').INVOICE1 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE1_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE2', false)"
                        v-if="$CONST('PRINTOUTS').INVOICE2 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE2_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE3', false)"
                        v-if="$CONST('PRINTOUTS').INVOICE3 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE3_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE4', false)"
                        v-if="$CONST('PRINTOUTS').INVOICE4 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE4_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE5', false)"
                        v-if="$CONST('PRINTOUTS').INVOICE5 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE5_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE6', false)"
                        v-if="$CONST('PRINTOUTS').INVOICE6 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE6_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenRechnungPdfCache('INVOICE7', false)"
                        v-if="$CONST('PRINTOUTS').INVOICE7 != null"
                      >
                        {{ $CONST("PRINTOUTS").INVOICE7_LABEL }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenKopiekostenPdfCache('INVOICE8', true)"
                        v-if="
                          (baseURL =
                            'https://tsp.bx-education.ch/' &&
                            $CONST('PRINTOUTS').INVOICE8 != null)
                        "
                      >
                        <span>{{ $CONST("PRINTOUTS").INVOICE8_LABEL }}</span>
                      </b-dropdown-item>
                    </b-dropdown>

                    <button
                      class="btn btn-primary mr-2"
                      @click="confirmBuchen"
                      v-if="berechtigungen.m_finanzen.update"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-book"
                        class="mr-2"
                      />
                      {{ $t("global.book") }} ({{
                        Array.from(new Set(selectedIds)).length
                      }})
                    </button>

                    <button
                      v-if="berechtigungen.m_finanzen.update"
                      class="btn btn-primary mr-2"
                      @click="confirmStorno"
                    >
                      <font-awesome-icon icon="fa-solid fa-ban" class="mr-2" />
                      {{ $t("global.Cancel") }} ({{
                        Array.from(new Set(selectedIds)).length
                      }})
                    </button>
                    <button
                      class="btn btn-primary mr-2"
                      @click="
                        exportToExcelWithPlugin(
                          selectedIds,
                          'invoice',
                          'Rechnungen'
                        )
                      "
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.export") }}
                    </button>
                    <!-- Ausgebledet für alle ausser HEI -->
                    <!--  <button
                      class="btn btn-primary mr-2"
                      @click="exportToBexio()"
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-share"
                      /></span>
                      Bexio Export
                    </button> -->
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_finanzen.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      {{ $t("global.delete") }} ({{
                        Array.from(new Set(selectedIds)).length
                      }})
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    no-local-sorting
                    @sort-changed="sortingChanged"
                    :sort-compare="sortDates"
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template v-slot:cell(auftragsstatus)="row">
                      <div
                        v-if="row.item.auftragsstatus.id == '19242F23FC8'"
                        class="status"
                        :class="statusBackground(row.item)"
                        v-tooltip.hover="
                          (text = getTimestamp(row.item.bemerkungen))
                        "
                      >
                        {{ row.item.auftragsstatus.bezeichnung }}
                      </div>
                      <div
                        v-else
                        class="status"
                        :class="statusBackground(row.item)"
                      >
                        {{ row.item.auftragsstatus.bezeichnung }}
                      </div>
                    </template>
                    <template v-slot:cell(zahlungstatus)="row">
                      <div
                        :style="
                          row.item.zahlungstatus
                            ? row.item.zahlungstatus.farbcode
                              ? 'background-color:#' +
                                row.item.zahlungstatus.farbcode
                              : ''
                            : ''
                        "
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(buchungsstatus)="row">
                      <div
                        :style="
                          row.item.buchungsstatus
                            ? row.item.buchungsstatus.farbcode
                              ? 'background-color:#' +
                                row.item.buchungsstatus.farbcode
                              : ''
                            : ''
                        "
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <messagebox-enhanced
      :headerText="$t('global.postinvoices')"
      id="buchen-alert-msgbox"
      :ok="true"
      :cancel="true"
      :okText="$t('global.post')"
      :cancelText="$t('global.delete')"
      @ok="verbuchen"
    />

    <messagebox-count
      :headerText="$t('global.invoicesposted')"
      id="count-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
      @ok="reload"
    />

    <messagebox-count
      :headerText="$t('global.invoicescancelled')"
      id="count-storno-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
      @ok="reload"
    />

    <messagebox-count
      :headerText="$t('global.invoicesdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <messagebox-error
      :headerTitel="$t('global.invoicescancelled')"
      :errorText="$t('global.notification_failed')"
      id="error-storno-alert-msgbox"
      @ok="reload"
    />

    <messagebox-error
      :headerTitel="$t('global.BuchungFehlgeschlagen')"
      :errorText="$t('global.notification_failed')"
      id="error-buchen-alert-msgbox"
      @ok="reload"
    />
    <response-modal headerTitel="Bexio Export" @ok="reload" />

    <loeschen-modal
      id="auftrag-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
      @confirmed="auftragLoeschen"
    />

    <auftrag-stornieren-modal
      id="auftrag-stornieren-modal"
      @stornieren="stornieren"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
      :auftrag="selectedObjects[0] ? selectedObjects[0] : null"
      :person="selectedObjects[0] ? selectedObjects[0].person : null"
      :firma="selectedObjects[0] ? selectedObjects[0].firma : null"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import AppApi from "@/AppApi";
import HeadMenu from "@/components/HeadMenu";
import CarboneApi from "@/CarboneApi";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import excelExport from "@/mixins/ExcelExport";
import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";
import MessageboxError from "@/components/Modals/MessageboxError.vue";
import ResponseModal from "@/components/Modals/ResponseModal.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import { LADE_AUSWAHLWERTE_AUFTRAG } from "@/store/auftrag/actions.type";
import { LADE_ARTIKEL } from "@/store/artikel/actions.type";
import { LADE_FINANZ_INFORMATIONEN } from "@/store/finanzen/actions.type";
import { LADE_AUSWAHLWERTE_DEBITOR } from "@/store/debitor/actions.type";

import AuftragStornierenModal from "@/components/Modals/AuftragStornierenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import numeral from "numeral";
import DatePicker from "@/components/Datepicker";
import server from "@/server";

export default {
  name: "Rechnungsliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    DatePicker,
    MessageboxEnhanced,
    MessageboxError,
    ResponseModal,
    LoeschenModal,
    AuftragStornierenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.orders");
      },
    };
  },
  mixins: [page, dashboard, filter, excelExport],
  props: {},
  store,
  data() {
    return {
      sortField: null,
      sortDir: false,
      baseURL: this.$CONST("CONFIG").BASEURL,
      pdfFileName: "",
      pdfPath: "",
      pdfFiles: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "auftragsstatus",
          label: this.$t("global.orderstatus"),
          searchable: false,
          sortable: true,
        },

        {
          key: "auftragsnummer",
          label: this.$t("global.invoicenr"),
          sortable: true,
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "rechnungsdatum",
          label: this.$t("global.invoicedate"),
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "totalBrutto",
          label: this.$t("global.totalgross"),
          sortable: true,
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "buchungsstatus",
          searchable: false,
          sortable: true,
          sortKey: "sortierung",
          label: this.$t("global.bookingstatus"),
          formatter: (value) => {
            return value ? value.bezeichnung : "";
          },
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "zahlungstatus",
          searchable: false,
          sortable: true,
          label: this.$t("global.paymentstatus"),
          formatter: (value) => {
            return value ? value.bezeichnung : "";
          },
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "zahlungsausgleichstatus",
          searchable: false,
          sortable: true,
          label: this.$t("global.paymentsettlementstatus"),
          formatter: (value) => {
            return value ? value.bezeichnung : "";
          },
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "rechnung_an",
          searchable: true,
          label: this.$t("global.invoiceaddress"),
          /*
            if (value) {
              if (value.firma) {
                return `${value.firma}` + "\n" + adresse.toSmallString(value);
              } else {
                return adresse.toSmallString(value);
              }
            } else return "-";
            },
            */
        },
      ];
    },
    queryParams: function () {
      var params = {};

      if (this.sortField) {
        params.sdir = this.sortDir;
        params.sfield = this.sortField;
      }

      params.filterAuftragnr = this.filters.auftragsnummer;
      params.filterBuchungstatus = this.setQueryParamList(
        this.filters.buchungstatus
      );
      params.filterAuftragstatus = this.setQueryParamList(
        this.filters.auftragstatus
      );
      params.filterZahlungstatus = this.setQueryParamList(
        this.filters.zahlungstatus
      );
      if (this.filters.rechnung_an != null && this.filters.rechnung_an != "") {
        params.filterRechnungsadresse = this.filters.rechnung_an;
      } else if (this.filters.firma != null && this.filters.firma != "") {
        params.filterRechnungsadresse = this.filters.firma;
      }

      if (this.filters.rechnungsdatum && this.filters.rechnungsdatum != "..")
        params.filterRechnungsdatum = this.filters.rechnungsdatum;

      if (
        this.filters.rechnungsdatum_von &&
        this.filters.rechnungsdatum_von != ".."
      )
        params.filterRechnungsdatumVon = this.filters.rechnungsdatum_von;

      if (
        this.filters.rechnungsdatum_bis &&
        this.filters.rechnungsdatum_bis != ".."
      )
        params.filterRechnungsdatumBis = this.filters.rechnungsdatum_bis;

      params.filterBrutto = this.filters.totalBrutto;
      params.filterBruttoVon = this.filters.totalBruttoVon;
      params.filterBruttoBis = this.filters.totalBruttoBis;
      params.filterPerson = this.filters["person.personName"];
      params.filterArtikel = this.setQueryParamList(
        this.filters.artikel,
        "artikelnummer"
      );

      // TODO

      // params.filterASBez = this.filters['auftragsstatus.bezeichnung']
      // params.filterNetto = this.filters.totalNetto;
      // params.filterBSBez = this.filters['buchungsstatus.bezeichnung']
      // params.filterZSBez = this.filters['zahlungstatus.bezeichnung']
      //params.filterRGAdr = this.filters["rechnungsadresse"];

      if (this.filters.count != "") params.filterCount = this.filters.count;

      return params;
    },
    buchungsstati: {
      get() {
        return this.$store.state.auftrag.buchungsstati;
      },
    },
    auftragsstati: {
      get() {
        return this.$store.state.auftrag.auftragsstati;
      },
    },
    zahlungsstati: {
      get() {
        return this.$store.state.debitor.zahlungstati;
      },
    },
    geschaeftsjahre: {
      get() {
        return this.$store.state.finanzen.geschaeftsjahre;
      },
    },
    artikelListe() {
      return this.$store.state.artikel.artikel;
    },
    geschaeftsjahr() {
      return this.$store.state.finanzen.geschaeftsjahr;
    },
    valideAuftragBuchung() {
      if (this.selectedIds.length == 0) return null;

      // Aufträge ermitteln

      let selectedAuftraege = this.listData.filter((auftrag) =>
        this.selectedIds.includes(auftrag.id)
      );

      // Prüfen welche Aufträge verarbeitet werden dürfen
      let that = this;

      return selectedAuftraege.filter(
        (auftrag) =>
          auftrag.rechnungsdatum &&
          this.checkGeschaeftsjahr(auftrag) &&
          auftrag.buchungsstatus.id == that.$CONST("BUCHUNGSTATI").NOTBOOKED &&
          auftrag.auftragsstatus.id !== "175207FE388" && // Offerte
          auftrag.auftragsstatus.id !== "175207FE386" && // Auftrag
          auftrag.auftragsstatus.id !== "17F1D12B409" // Entwurf
      );
      /*
      return selectedAuftraege.filter(
        (auftrag) =>
          auftrag.rechnungsdatum &&
          that.geschaeftsjahr &&
          parseDateString(auftrag.rechnungsdatum) >=
            that.geschaeftsjahr.start &&
          parseDateString(auftrag.rechnungsdatum) <= that.geschaeftsjahr.ende &&
          auftrag.buchungsstatus.id == that.$CONST("BUCHUNGSTATI").NOTBOOKED
      );*/
    },
    invalideAuftragBuchung() {
      if (this.selectedIds.length == 0) return null;

      // Aufträge ermitteln

      let selectedAuftraege = this.listData.filter((auftrag) =>
        this.selectedIds.includes(auftrag.id)
      );

      // Prüfen welche Aufträge nicht verarbeitet werden dürfen
      let that = this;

      return selectedAuftraege.filter(
        (auftrag) =>
          (auftrag.rechnungsdatum &&
            (!this.checkGeschaeftsjahr(auftrag) ||
              auftrag.buchungsstatus.id !=
                that.$CONST("BUCHUNGSTATI").NOTBOOKED)) ||
          auftrag.auftragsstatus.id === "175207FE388" || // Offerte
          auftrag.auftragsstatus.id === "175207FE386" || // Auftrag
          auftrag.auftragsstatus.id === "17F1D12B409" || // Entwurf
          auftrag.auftragsstatus.id === "1922D159B6A" || // Offerte Angenommen
          auftrag.auftragsstatus.id === "1922D166D01" || // Offerte Abgelehnt
          auftrag.auftragsstatus.id === "19242F23FC8"    // Weitergeleitet
      );
      /*
      return selectedAuftraege.filter(
        (auftrag) =>
          auftrag.rechnungsdatum &&
          that.geschaeftsjahr &&
          parseDateString(auftrag.rechnungsdatum) >=
            that.geschaeftsjahr.start &&
          parseDateString(auftrag.rechnungsdatum) <= that.geschaeftsjahr.ende &&
          auftrag.buchungsstatus.id == that.$CONST("BUCHUNGSTATI").NOTBOOKED
      );*/
    },
    valideAuftragStorno() {
      if (this.selectedIds.length == 0) return null;

      // Aufträge ermitteln

      let selectedAuftraege = this.listData.filter((auftrag) =>
        this.selectedIds.includes(auftrag.id)
      );

      // Prüfen welche Aufträge verarbeitet werden dürfen
      let that = this;

      return selectedAuftraege.filter(
        (auftrag) =>
          auftrag.rechnungsdatum &&
          this.checkGeschaeftsjahr(auftrag) &&
          auftrag.buchungsstatus.id == that.$CONST("BUCHUNGSTATI").BOOKED
      );
    },
    invalideAuftragStorno() {
      if (this.selectedIds.length == 0) return null;

      // Aufträge ermitteln

      let selectedAuftraege = this.listData.filter((auftrag) =>
        this.selectedIds.includes(auftrag.id)
      );

      // Prüfen welche Aufträge nicht verarbeitet werden dürfen
      let that = this;

      return selectedAuftraege.filter(
        (auftrag) =>
          (auftrag.rechnungsdatum &&
            (!this.checkGeschaeftsjahr(auftrag) ||
              auftrag.buchungsstatus.id !=
                that.$CONST("BUCHUNGSTATI").BOOKED)) ||
          auftrag.zahlungstatus.id != "1751CCCFA0B" // 1751CCCFA0B = Zahlungsstatus Unbezahlt
      );
    },
  },
  created() {
    if (this.artikelListe.length == 0)
      this.$store.dispatch(`artikel/${LADE_ARTIKEL}`);

    if (this.buchungsstati.length == 0)
      this.$store.dispatch(`auftrag/${LADE_AUSWAHLWERTE_AUFTRAG}`);

    if (this.geschaeftsjahr == null)
      this.$store.dispatch(`finanzen/${LADE_FINANZ_INFORMATIONEN}`);

    if (this.zahlungsstati.length == 0)
      this.$store.dispatch(`debitor/${LADE_AUSWAHLWERTE_DEBITOR}`);

    this.initFilter("auftraege-liste", "auftrag/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    validCarbonePrintouts(constant) {
      const validCarbonePrintouts = [];

      for (const key in constant) {
        const carboneprint = constant[key];
        if (this.isValidCarbonePrintout(carboneprint)) {
          validCarbonePrintouts.push(carboneprint);
        }
      }

      return validCarbonePrintouts;
    },

    carbone(category, templateID, endpoint) {
      if (!this.selectedIds) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
      } else {
        CarboneApi.get(
          endpoint +
            this.selectedIds +
            "&category=" +
            category +
            "&templateId=" +
            templateID
        )
          .then((response) => {
            this.pdfFiles = response.data.map((item) => ({
              url: server.url + item.url,
              name: item.name,
              count: item.count,
              size: item.name,
            }));
            this.pdfFiles.length > 0
              ? this.$refs.pdfLayer.show()
              : this.$notify({
                  type: "warn",
                  title: this.$t("global.actionfailed"),
                  text: "Es ist ein Fehler aufgetreten",
                });
          })
          .catch((error) => {
            console.error("Error fetching PDF files:", error);
          })
          .finally(() => {
            this.$refs.pdfLayer.loading = false;
          });
      }
    },
    reload() {
      this.resetLoading(this);
    },
    neuerAuftrag() {
      this.$router.push({ name: "auftrag.neu" });
    },

    details(rechnung) {
      if (rechnung.id) {
        this.$router.push({
          name: "auftrag.id.anzeige",
          params: { id: rechnung.id, anzeige: 0 },
        });
      }
    },

    sortingChanged(ctx) {
      this.sortDir = ctx.sortDesc;
      this.sortField = ctx.sortBy;
      this.resetLoading(this);
    },

    confirmBuchen() {
      if (this.selectedIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });

        return;
      }

      if (
        this.invalideAuftragBuchung &&
        this.invalideAuftragBuchung.length > 0
      ) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.atleastoneunpostedinvoiceselected"),
        });
      } else if (
        this.valideAuftragBuchung &&
        this.valideAuftragBuchung.length > 0
      ) {
        this.$bus.$emit("openMessageBoxEnhanced", {
          id: "buchen-alert-msgbox",
          text: this.$t("global.doyouwanttoposttheinvoices"),
        });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noneoftheinvoicescanbebooked"),
        });
      }
    },

    /*** AKTIONEN ***/

    verbuchen() {
      let valideIds = this.valideAuftragBuchung.map((auftrag) => auftrag.id);

      Api.post("auftrag/buchen/", valideIds)
        .then((response) => {
          /*
          this.$notify({
            type: "success",
            title: "Aktion erfolgreich",
            text: "Rechnung(en) erfolgreich gebucht",
          });
          */
          if (response.data.success.length != 0) {
            this.$bus.$emit("openMessageBoxCount", {
              id: "count-alert-msgbox",
              successText: this.$t("global.successful") + ": ",
              successCount: response.data.success.length,
            });
          } else {
            this.$bus.$emit("openMessageboxError", {
              data: response.data.error,
              id: "error-buchen-alert-msgbox",
            });
          }
          this.selectedIds = [];
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },

    confirmStorno() {
      if (this.selectedIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });

        return;
      }
      if (this.invalideAuftragStorno && this.invalideAuftragStorno.length > 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.atleastoneunpostedinvoiceselected"),
        });
      } else if (
        this.valideAuftragStorno &&
        this.valideAuftragStorno.length > 0
      ) {
        this.$bus.$emit("oeffneAuftragStornierenModal", {
          id: "auftrag-stornieren-modal",
        });
        /*
        this.$bus.$emit("openMessageBoxEnhanced", {
          id: "storno-alert-msgbox",
          text: "Wollen Sie die gewählten Rechnungen wirklich stornieren?",
        });
        */
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.unpostedorcanceledinvoicesselected"),
        });
      }
    },

    stornieren(stornierungsgrund, stornierungsdatum) {
      let valideIds = this.valideAuftragStorno.map((auftrag) => auftrag.id);

      Api.put("auftrag/stornieren/", {
        ids: valideIds,
        stornierungsgrund: stornierungsgrund,
        stornierungsdatum: stornierungsdatum,
      })
        .then((response) => {
          console.log(response.data);
          /*
          this.$notify({
            type: "success",
            title: "Aktion erfolgreich",
            text: "Rechnung(en) storniert",
          });
          */
          if (response.data.success.length != 0) {
            this.$bus.$emit("openMessageBoxCount", {
              id: "count-storno-alert-msgbox",
              successText: this.$t("global.successful") + ": ",
              successCount: response.data.success.length,
            });
          } else {
            this.$bus.$emit("openMessageboxError", {
              data: response.data.error,
              id: "error-storno-alert-msgbox",
            });
          }
          this.selectedIds = [];
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },

    auftragLoeschen() {
      this.delete("auftrag/", this.selectedIds);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });

        return;
      }

      // Aufträge ermitteln

      let selectedAuftraege = this.listData.filter((auftrag) =>
        this.selectedIds.includes(auftrag.id)
      );

      // Prüfen welche Aufträge verarbeitet werden dürfen

      let that = this;

      let invalideAuftraege = selectedAuftraege.filter(
        (auftrag) =>
          auftrag.buchungsstatus.id == that.$CONST("BUCHUNGSTATI").BOOKED ||
          auftrag.buchungsstatus.id == that.$CONST("BUCHUNGSTATI").CANCELED ||
          !this.checkGeschaeftsjahr(auftrag)
      );

      if (invalideAuftraege.length == 0) {
        this.$bus.$emit("openLoeschenModal", "auftrag-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t(
            "global.atleastoneinvoicehasbeenbookedorisaclosedyearandcannotbedeleted"
          ),
        });
      }
    },

    checkGeschaeftsjahr(auftrag) {
      let success = false;
      if (auftrag.rechnungsdatum) {
        let geschaeftsjahrDatum = new Date();
      let rechnungsdatumJahr = this.parseDate(
        auftrag.rechnungsdatum
      ).getFullYear();

      this.geschaeftsjahre.forEach((geschaeftsjahr) => {
        geschaeftsjahrDatum = this.parseDate(
          geschaeftsjahr.start
        ).getFullYear();
        if (
          geschaeftsjahrDatum <= rechnungsdatumJahr &&
          geschaeftsjahr.geschaeftsjahrstatus.bezeichnung == "Offen"
        ) {
          success = true;
        }
      });
      } else success = true;

      return success;
    },

    parseDate(date) {
      var parts = date.match(/(\d+)/g);

      return new Date(parts[2], parts[1] - 1, parts[0]);
    },

    // *** PRINTOUTS ***/

    ausgebenRechnungPdfCache(invoiceKey, selected) {
      this.pdfFiles = [];

      let printIds = selected
        ? this.$refs.selectableTable.items
            .filter((item) => item.selected)
            .map((item) => item.id)
        : this.$refs.selectableTable.items.map((item) => item.id);

      if (printIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
        return;
      }

      /*  if (printIds.length > 50) {
        this.$notify({
          type: "warn",
          title: "Anfrage konnte nicht ausgeführt werden.",
          text: "Zu viele Datensätze ausgewählt. Es können maximal 50 Datensätze gedruckt werden.",
        });
        return;
      } */ // Begrenzung muss noch abgeklärt werden

      if (printIds > 100) {
        let index = 1,
          counter = parseInt(printIds.length / 100, 10);

        if (parseInt(printIds.length / 100, 10) != printIds.length / 100)
          counter++;

        for (var i = 0; i < printIds.length; i += 100) {
          let subList = printIds.slice(i, i + 100);
          let ids = subList.join(",");

          this.generatePDF(invoiceKey, index, ids, counter, subList.length);
          index++;
        }
      } else {
        let ids = printIds.join(",");
        this.generatePDF(invoiceKey, 1, ids, 1, printIds.length);
      }

      this.$refs.pdfLayer.show();
    },

    generatePDF(invoiceKey, index, ids, counter, size) {
      let json = {
        idlist: ids,
      };

      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=RG" +
          filename +
          ".pdf&report=/api" +
          this.$CONST("PRINTOUTS")[invoiceKey] +
          "&cache=" +
          cacheId;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            size: size,
            url:
              (server.url && server.url != "" ? server.url : "") +
              response.data.filepath,
            withCredentials: true,
            count: counter,
          };

          this.pdfFiles.push(retValue);
        });
      });
    },

    isValidCarbonePrintout(printout) {
      let isValid = false;
      if (printout.appkey.includes("offerte" || "rechnung" || "mahnung")) {
        isValid = true;
      }
      return isValid;
    },

    ausgebenRechnung(invoiceKey) {
      if (this.selectedIds.length > 0) {
        this.selectedIds = Array.from(new Set(this.selectedIds));
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        this.pdfPath =
          server.url +
          "/pdfgenerator/pdf.act?filename=RG" +
          filename +
          ".pdf&report=" +
          this.$CONST("PRINTOUTS")[invoiceKey] +
          "&ids=" +
          this.selectedIds;
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
      }
    },
    ausgebenKopiekostenPdfCache(invoiceKey, selected) {
      this.pdfFiles = [];

      let printIds = selected
        ? this.$refs.selectableTable.items
            .filter((item) => item.selected)
            .map((item) => item.id)
        : this.$refs.selectableTable.items.map((item) => item.id);

      if (printIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
        return;
      }

      if (printIds.length > 2000) {
        this.$notify({
          type: "warn",
          title: this.$t("global.requestcouldnotbeexecuted"),
          text: this.$t("global.toomanyrecordsselected"),
        });
        return;
      } // Begrenzung muss noch abgeklärt werden

      if (printIds > 100) {
        let index = 1,
          counter = parseInt(printIds.length / 100, 10);

        if (parseInt(printIds.length / 100, 10) != printIds.length / 100)
          counter++;

        for (var i = 0; i < printIds.length; i += 100) {
          let subList = printIds.slice(i, i + 100);
          let ids = subList.join(",");

          this.generateKopiekostenPDF(
            invoiceKey,
            index,
            ids,
            counter,
            subList.length
          );
          index++;
        }
      } else {
        let ids = printIds.join(",");
        this.generateKopiekostenPDF(invoiceKey, 1, ids, 1, printIds.length);
      }

      this.$refs.pdfLayer.show();
    },
    generateKopiekostenPDF(invoiceKey, index, ids, counter, size) {
      let json = {
        idlist: ids,
      };

      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=Kopiekosten_" +
          filename +
          ".pdf&report=/api" +
          this.$CONST("PRINTOUTS")[invoiceKey] +
          "&cache=" +
          cacheId +
          "&date=" +
          filename;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            size: size,
            url:
              (server.url && server.url != "" ? server.url : "") +
              response.data.filepath,
            withCredentials: true,
            count: counter,
          };

          this.pdfFiles.push(retValue);
        });
      });
    },
    ausgebenKopiekosten(invoiceKey) {
      if (this.selectedIds.length > 0) {
        this.selectedIds = Array.from(new Set(this.selectedIds));
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        this.pdfPath =
          server.url +
          "/pdfgenerator/pdf.act?filename=Kopiekosten_" +
          filename +
          ".pdf&report=" +
          this.$CONST("PRINTOUTS")[invoiceKey] +
          "&ids=" +
          this.selectedIds;
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
      }
    },
    exportToBexio() {
      this.loading = true;

      if (this.selectedIds.length > 0) {
        AppApi.post("bexio/", this.selectedIds)
          .then((response) => {
            this.selectedIds = [];
            if (response.data) {
              this.$bus.$emit("openResponseModal", {
                data: response.data,
                id: "response-modal",
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$notify({
          type: "warning",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noinvoiceselected"),
        });
        this.loading = false;
      }
    },
    getTimestamp(remark) {
      if (remark == null) return "";
      // Split the remark by newline characters
      const lines = remark.split("\n");

      // Search for the line that contains the timestamp
      const regex = /Auftrag\s.*?\sam\s\d{2}\.\d{2}\.\d{4}\s\d{2}:\d{2}:\d{2}/;
      const timestampLine = lines.find((line) => regex.test(line));

      // If we find a valid timestamp line, return it, otherwise return an empty string
      return timestampLine ? timestampLine.match(regex)[0] : "";
    },
    statusBackground(item) {
      let status = item.auftragsstatus;

      if (status) {
        //Entwurf:
        if (status.id == "17F1D12B409") {
          return "statusWarn";
        }
        //Offerte angenommen:
        else if (status.id == "1922D159B6A") {
          return "statusSuccess";
        }
        //Offerte abgelehnt:
        else if (status.id == "1922D166D01") {
          return "statusDanger";
        }
        //Offerte
        else if (status.id == "175207FE388") {
          return "statusViolett";
        }
        // Weitergeleitet
        else if (status.id == "19242F23FC8") {
          return "statusGrey";
        }
        // Rechnung
        else if (status.id == "175207FE38A") {
          return "statusPrimary";
        }
        // Auftrag
        else if (status.id == "175207FE386") {
          return "statusPrimarylight";
        }
      } else return "";
    },
  },
};
</script>
<style></style>
